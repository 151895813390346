import React, { useEffect, useState } from 'react'
import './demo.css'
import { Grid, Row, Col } from 'react-flexbox-grid';



function Demo({ walletConnection, currentUser, contract, nearConfig }) {

    const [width, setWindowWidth] = useState(0);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isChatView, setIsChatView] = useState(false);

    // componentDidMount...runs only once
    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [])


    const updateDimensions = () => {
        const width = window.innerWidth;
        if (width < 480)
            setIsMobileView(true);
        else
            setIsMobileView(false);
        setWindowWidth(width);
    };

    const mobileTopView = () => {
        console.log(isMobileView)
        return <Row onClick={() => setIsChatView(false)} style={{ height: '10vh', background: 'pink' }}>
            logo
        </Row>;
    }





    const chatHeaderView = () => {
        return <Row onClick={() => isMobileView ? setIsChatView(true) : () => { }} style={{ height: '5vh', background: 'orange' }}>
            chatHeaderView
        </Row>

    }

    const chatBodyView = () => {
        return <Row onClick={() => isMobileView ? setIsChatView(true) : () => { }} style={{ height: '70vh', background: 'grey' }}>
            chatHeaderView
        </Row>

    }

    const chatFooterView = () => {
        return <Row style={{ height: '5vh', }}>
            chatFooterView
        </Row>
    }

    const chatView = () => {
        return <Col xs={0} sm={7} md={8} lg={8} style={{ background: 'red' }}>
            {isMobileView ?
                mobileTopView()
                : <div></div>
            }
            {chatHeaderView()}
            {chatBodyView()}
            {chatFooterView()}
        </Col>;
    }


    const contactHeaderView = () => {
        return <Row onClick={() => isMobileView ? setIsChatView(true) : () => { }} style={{ height: '5vh', background: 'orange' }}>
            contactHeaderView
        </Row>

    }

    const contactBodyView = () => {
        return <Row onClick={() => isMobileView ? setIsChatView(true) : () => { }} style={{ height: '70vh', background: 'grey' }}>
            contactBodyView
        </Row>

    }

    const contactFooterView = () => {
        return <Row style={{ height: '5vh', }}>
            contactFooterView
        </Row>
    }

    const contactView = () => {
        return <Col xs={12} sm={5} md={4} lg={3} style={{ background: 'blue'}} >
            {isMobileView ?
                mobileTopView()
                : <div></div>
            }
            {contactHeaderView()}
            {contactBodyView()}
            {contactFooterView()}
        </Col>;
    }

    return (
        <Grid fluid>
            <Row className='flex-container' between='lg'>

                {isChatView ?
                    chatView()
                    : contactView()
                }


                {isMobileView ?
                    <div></div>
                    : chatView()
                }
            </Row>
        </Grid>
    )

}

export default Demo
