/* A helper file that simplifies using the wallet selector */

// near api js
import { providers, connect, keyStores, WalletConnection, utils } from 'near-api-js';
import * as nearAPI from "near-api-js";
// wallet selector UI
import '@near-wallet-selector/modal-ui/styles.css';
import { setupModal } from '@near-wallet-selector/modal-ui';
import LedgerIconUrl from '@near-wallet-selector/ledger/assets/ledger-icon.png';
import MyNearIconUrl from '@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png';

// wallet selector options
import { setupWalletSelector } from '@near-wallet-selector/core';
import { setupLedger } from '@near-wallet-selector/ledger';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";


const THIRTY_TGAS = '30000000000000';
const NO_DEPOSIT = '0000000000000000000';

const adminAccount = 'drop-v1.fortuna-draco.near';
const adminPK = 'ed25519:28koEtF8cXM6i21t8ksiDFDA9jcgc2DgppieEvkJJDKu3Wi8nFUskt36RZk4cHPmXnEzcypmny7AuJZVdQWjKeN8';

const DRAGON_CONTRACT = "v1.fortuna-draco.near"
const NETWORK_RPC = "https://black-withered-haze.near-mainnet.quiknode.pro/836ed6e8b56ff15333f88f4cdd44cd3ea68787d9/"
// Wallet that simplifies using the wallet selector
export class Wallet {
  walletSelector;
  wallet;
  network;
  createAccessKeyFor;
  nearConnection;
  walletConnection;
  account;
  accountId;
  isSignedIn = false;

  constructor({ createAccessKeyFor = DRAGON_CONTRACT, network = 'mainnet' }) {
    this.createAccessKeyFor = createAccessKeyFor; //createAccessKeyFor;
    this.network = network;
    this.init();
  }

  async init() {
    // this.walletSelector = await setupWalletSelector({
    //   network: this.network,
    //   modules: [setupMyNearWallet({ iconUrl: MyNearIconUrl }), setupMeteorWallet(), setupHereWallet(),
    //   setupLedger({ iconUrl: LedgerIconUrl })],
    // });

    this.walletSelector = await setupWalletSelector({
      network: this.network,
      modules: [setupMyNearWallet({ iconUrl: MyNearIconUrl }), setupMeteorWallet(), setupHereWallet()],
    });

    this.isSignedIn = this.walletSelector.isSignedIn();

    if (this.isSignedIn) {
      this.wallet = await this.walletSelector.wallet();
      this.accountId = this.walletSelector.store.getState().accounts[0].accountId;
    }

    return this.isSignedIn;
  }

  async checkSignIn() {
    this.isSignedIn = this.walletSelector.isSignedIn();

    if (this.isSignedIn) {
      this.wallet = await this.walletSelector.wallet();
      this.accountId = this.walletSelector.store.getState().accounts[0].accountId;
    } else {
      this.wallet = this.accountId = this.createAccessKeyFor = null;
    }

    return this.isSignedIn;
  }


  // Sign-in method
  signIn() {
    const description = 'Please select a wallet to sign in.';
    const modal = setupModal(this.walletSelector, { contractId: this.createAccessKeyFor, description });
    modal.show();

  }

  // Sign-out method
  async signOut() {

    // await 
    this.isSignedIn = false;
    this.wallet.signOut();
    this.wallet = this.accountId = this.createAccessKeyFor = null;
    localStorage.clear();

    // window.location.replace(window.location.origin + window.location.pathname);
  }

  // Make a read-only call to retrieve information from the network
  async viewMethod({ contractId, method, args = {} }) {
    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });


    let res = await provider.query({
      request_type: 'call_function',
      account_id: contractId,
      method_name: method,
      args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
      finality: 'optimistic',
    });
    return JSON.parse(Buffer.from(res.result).toString());
  }

  // Call a method that changes the contract's state
  async callMethod({ contractId, method, args = {}, gas = THIRTY_TGAS, deposit = NO_DEPOSIT }) {
    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: NETWORK_RPC });


    // Sign a transaction with the "FunctionCall" action
    let val = await this.wallet.signAndSendTransaction({
      signerId: this.accountId,
      receiverId: contractId,
      actions: [
        {
          type: 'FunctionCall',
          params: {
            methodName: method,
            args,
            gas,
            deposit,
          },
        },
      ],
    });
    // return provider.getTransactionLastResult(val);
    return val;
  }

  // Call a method that changes the contract's state
  async deployContract({ args = any, gas = THIRTY_TGAS, deposit = NO_DEPOSIT }) {
    // // Sign a transaction with the "FunctionCall" action
    // console.log("Connecting to account...");


    let ab = await this.account.deployContract(new Uint8Array(args));
    console.log(ab);
    return "ok";

  }


  // Get transaction result from the network
  async getTransactionResult(txhash) {
    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: NETWORK_RPC });

    // Retrieve transaction result from the network
    const transaction = await provider.txStatus(txhash, 'unnused');
    return providers.getTransactionLastResult(transaction);
  }



  async getBalanceOfCustomToken(contractId) {
    const method = "ft_balance_of";
    const args = { account_id: this.accountId };
    let amount = await this.viewMethod({ contractId, method, args });
    //Todo: Make it dynamic
    if (contractId == "token.0xshitzu.near") {
      return this.formatTokenAmount(amount, 18, 2);
    }

    return utils.format.formatNearAmount(amount, 2);

  }

  // Function to format token amounts based on the token's decimal precision
  formatTokenAmount(rawAmount, decimals = 18, displayDecimals = 2) {
    // Ensure rawAmount is treated as a string to handle big numbers safely
    const rawAmountStr = rawAmount.toString();
    // Pad the raw amount with leading zeros if necessary
    const paddedAmount = rawAmountStr.padStart(decimals + 1, '0');
    // Insert decimal point at the correct position
    const intPart = paddedAmount.slice(0, -decimals) || '0';
    const decimalPart = paddedAmount.slice(-decimals).padEnd(decimals, '0');
    // Format the decimal part
    const formattedDecimalPart = decimalPart.slice(0, displayDecimals);
    // Combine the integer and decimal parts
    const formattedAmount = `${intPart}.${formattedDecimalPart}`;
    return formattedAmount;
  }

  async getBalance() {
    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

    let state = await provider.query({
      request_type: 'view_account',
      account_id: this.accountId,
      finality: 'optimistic'
    });

    // (Math.round(num * 100) / 100).toFixed(2);

    return utils.format.formatNearAmount(state.amount, 2);

  }


  generatedKeyPair() {
    let keyPair = nearAPI.KeyPair.fromRandom('ed25519');

    return keyPair;

  }

  //TODO: Move to different File 


  DROP_CONFIG = {
    // How many claims can each key have.
    usesPerKey: 1,

    usage: {
      /// Can the access key only call the claim method_name? Default to both method_name callable
      permissions: null,
      /// If claim is called, refund the deposit to the owner's balance. If None, default to false.
      refundDeposit: true,
      /// Should the drop be automatically deleted when all the keys are used? This is defaulted to false and
      /// Must be overwritten
      autoDeleteDrop: true,
      /// When this drop is deleted and it is the owner's *last* drop, automatically withdraw their balance.
      autoWithdraw: true
    },

    time: {
      /// Minimum block timestamp before keys can be used. If None, keys can be used immediately
      /// Measured in number of non-leap-nanoseconds since January 1, 1970 0:00:00 UTC.
      start: null,

      /// Block timestamp that keys must be before. If None, keys can be used indefinitely
      /// Measured in number of non-leap-nanoseconds since January 1, 1970 0:00:00 UTC.
      end: null,

      /// Time interval between each key use. If None, there is no delay between key uses.
      /// Measured in number of non-leap-nanoseconds since January 1, 1970 0:00:00 UTC.
      throttle: null,

      /// Interval of time after the `start_timestamp` that must pass before a key can be used.
      /// If multiple intervals pass, the key can be used multiple times. This has nothing to do
      /// With the throttle timestamp. It only pertains to the start timestamp and the current
      /// timestamp. The last_used timestamp is not taken into account.
      /// Measured in number of non-leap-nanoseconds since January 1, 1970 0:00:00 UTC.
      interval: null
    },

    // Root account that all sub-accounts will default to. If None, default to the global drop root.
    dropRoot: null,
  }
  DROP_METADATA = "";
  DEPOSIT_PER_USE_NEAR = "0.1";
  async createDrop(envelop) {
    console.log("Step 1: Create Drop")
    // TODO: check the balance of the token

    let keyPair = this.generatedKeyPair();
    console.log(keyPair);
    let contractId = DRAGON_CONTRACT; //"dev-1707041746959-84859040980008";
    let method = "create_drop";
    envelop.token = {
      ...envelop.token,
      keyPair: {
        publicKey: keyPair.publicKey.toString(),
        secretKey: keyPair.secretKey
      },
    }


    let totalAmount = envelop.token.amount.toString();
    let fAmount;

    if (envelop.token.contractId == "token.0xshitzu.near") {
      fAmount = this.parseTokenAmount(totalAmount, 18)
    } else {
      fAmount = utils.format.parseNearAmount(totalAmount);
    }

    let args = {
      public_keys: [keyPair.publicKey.toString()],
      deposit_per_use: utils.format.parseNearAmount(this.DEPOSIT_PER_USE_NEAR),
      config: {
        uses_per_key: 1,
        time: {
          start: this.DROP_CONFIG.time.start,
          end: this.DROP_CONFIG.time.end,
          throttle: this.DROP_CONFIG.time.throttle,
          interval: this.DROP_CONFIG.time.interval
        },
        usage: {
          permissions: this.DROP_CONFIG.usage.permissions,
          refund_deposit: this.DROP_CONFIG.usage.refundDeposit,
          auto_delete_drop: this.DROP_CONFIG.usage.autoDeleteDrop,
          auto_withdraw: this.DROP_CONFIG.usage.autoWithdraw

        },
        root_account_id: this.DROP_CONFIG.dropRoot

      },
      metadata: JSON.stringify(this.DROP_METADATA),
      ft: {
        contract_id: envelop.token.contractId,
        sender_id: envelop.token.senderId,
        balance_per_use: fAmount,
      }
    };

    let gas = "300000000000000";
    let deposit = utils.format.parseNearAmount("0.01");


    try {
      // let res = await this.callMethod({ contractId, method, args, gas, deposit });
      let res = await this.createDropByAdmin(contractId, method, args, gas, deposit);
      if (res) {
        let dropId = await this.getRecentDropId();
        // console.log('dropId: ==>>', dropId)
        if (dropId) {
          let transfer = await this.transferFundToContract(envelop, dropId);
          if (transfer) {
            return envelop;
          }
          return transfer;
        } else {
          return false;
        }
      }
      return res;
    } catch (e) {
      // console.log('error creating drop: ', e);
      return false;
    }
  }
  async checkDrop(pubKey) {
    console.log("checkDrop")
    let method = 'get_drop_information';
    let contractId = DRAGON_CONTRACT;//"dev-1707041746959-84859040980008";
    let args = {
      key: pubKey,
    };
    try {
      let res = await this.callMethod({ contractId, method, args });
      console.log("checkDrop", res)
      return res;
    } catch (e) {
      console.log('error checkDrop drop: ', e);
    }
  }

  async transferFundToContract(envelop, dropId) {
    console.log("Step 3: transferFundToContract ");
    try {
    let NUM_KEYS = 1;
    let receiverId = DRAGON_CONTRACT;//"dev-1707041746959-84859040980008";

    let totalAmount = (envelop.token.amount * NUM_KEYS * this.DROP_CONFIG.usesPerKey).toString();
    let fAmount;

    if (envelop.token.contractId == "token.0xshitzu.near") {
      fAmount = this.parseTokenAmount(totalAmount, 18)
    } else {
      fAmount = utils.format.parseNearAmount(totalAmount);
    }
   
    let method = "ft_transfer_call";
    let contractId = envelop.token.contractId;
    
    let args = {
      receiver_id: receiverId,
      amount: fAmount,
      msg: dropId.toString()
    };

    let gas = "300000000000000";
    let deposit = '1';

   
      let res = await this.callMethod({ contractId, method, args, gas, deposit });
      return res;
    } catch (e) {
      console.log('error transferFundToContract: ', e);
    }
  }

  parseTokenAmount(amountStr, decimals) {
    // Ensuring the amount is a string to avoid precision issues
    amountStr = amountStr.toString();

    // Splitting the amount into integer and fractional parts
    let [intPart, fracPart = ""] = amountStr.split(".");

    // Adjusting the fractional part to the correct length
    fracPart = fracPart.padEnd(decimals, "0").substring(0, decimals);

    // Removing leading zeros from the integer part
    intPart = intPart.replace(/^0+/, '') || "0";

    // Returning the combined string in minimal unit representation
    return `${intPart}${fracPart}`;
  }



  async createDropByAdmin(contractId, method, args, gas, deposit) {
    // console.log("Step 4:createDropByAdmin");

    // let contractId = "dev-1707041746959-84859040980008";
    // let method = "claim";
    // let args = { account_id: this.accountId }

    // let gas = "2500000000000";
    // let gas = "100000000000000";


    // let deposit = "1";


    // Custom Account sig


    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: NETWORK_RPC });

    let keyPair = nearAPI.KeyPair.fromString(adminPK);

    // Step 2:  load up an inMemorySigner using the keyPair for the account
    let signerContractAccount = await nearAPI.InMemorySigner.fromKeyPair(
      network.networkId,
      adminAccount,
      keyPair
    );

    network.deps = { keyStore: signerContractAccount.keyStore };
    let nearConnection = await nearAPI.connect({ ...network });

    let contractAccount = new nearAPI.Account(
      nearConnection.connection,
      adminAccount,
    );

    try {
      let res = await contractAccount.functionCall({
        contractId: contractId,
        methodName: method,
        args: args,
        gas: gas, // optional param, by the way
        attachedDeposit: deposit,
        walletMeta: "", // optional param, by the way
        walletCallbackUrl: "", // optional param, by the way
      });
      return res;
    } catch (e) {
      // console.log('error createDropByAdmin: ');
      return false;
    }
  }

  async claimToken(privateKey) {
    console.log("Step 4:claimToken");


    let contractId = DRAGON_CONTRACT;//"dev-1707041746959-84859040980008";
    let method = "claim";
    let args = { account_id: this.accountId }

    // let gas = "2500000000000";
    let gas = "100000000000000";


    let deposit = "1";


    // Custom Account sig


    const { network } = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: NETWORK_RPC });

    console.log(network);
    let keyPair = nearAPI.KeyPair.fromString(privateKey);

    // Step 2:  load up an inMemorySigner using the keyPair for the account
    let signerContractAccount = await nearAPI.InMemorySigner.fromKeyPair(
      network.networkId,
      contractId,
      keyPair
    );

    console.log(signerContractAccount)
    network.deps = { keyStore: signerContractAccount.keyStore };
    let nearConnection = await nearAPI.connect({ ...network });

    let contractAccount = new nearAPI.Account(
      nearConnection.connection,
      contractId,
    );
    console.log(contractAccount);
    try {
      let res = await contractAccount.functionCall({
        contractId: contractId,
        methodName: method,
        args: args,
        gas: gas, // optional param, by the way
        attachedDeposit: 0,
        walletMeta: "", // optional param, by the way
        walletCallbackUrl: "", // optional param, by the way
      });
      console.log(" check ....", res);
      return res;
    } catch (e) {
      console.log('error claimToken: ', e);
    }
  }

  // Estimate the amount of allowance required for a given attached gas.
  async getRecentDropId() {
    let methodSupplyForOwner = 'get_drop_supply_for_owner';

    let methodForOwner = 'get_drops_for_owner';
    let contractId = DRAGON_CONTRACT;// "dev-1707041746959-84859040980008";
    let args = { account_id: adminAccount }

    let dropSupplyForOwner = await this.viewMethod({ contractId, method: methodSupplyForOwner, args });


    args = {
      ...args,
      from_index: (dropSupplyForOwner - 1).toString()
    };

    let dropsForOwner = await this.viewMethod({ contractId, method: methodForOwner, args });



    return dropsForOwner[dropsForOwner.length - 1].drop_id;
  };
}