// export const FRONT_END_BASE_URL = "http://localhost:1234/";
// export const BACKEND_END_BASE_URL = "http://localhost:4000/";

export const TOKEN_PRICE_API = "https://api.coingecko.com/api/v3/coins/"

// // Test Env
// export const FRONT_END_BASE_URL_WITH = "http://170.64.150.226";
// export const FRONT_END_BASE_URL = "http://170.64.150.226/";

export const BACKEND_END_BASE_URL =   "https://icebreak.io/api/" //"http://95.179.198.163:4000/";//"http://170.64.150.226:4000/";
export const FRONT_END_BASE_URL_WITH ="https://icebreak.io/"  //"http://95.179.198.163/";
export const FRONT_END_BASE_URL = "https://icebreak.io" //"http://95.179.198.163/";
// old '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'
export const SEND_EMAIL_API_KEY = "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"