// import { getUnixTimestamp } from "@/helpers/getTime";
import { FireStore } from "./firebase";
import { arrayUnion, getDocs, limit, orderBy, query, updateDoc } from "firebase/firestore";
import FirestoreCrud from "./constant/FirestoreCrud";
import { collectionPrefix } from "./config/firebaseConfig"
import moment from "moment";

const RoomCollection = collectionPrefix + "rooms";

const EnvelopCollection = collectionPrefix + "envelop";


const EnvelopRef = FireStore.collection(EnvelopCollection);

const MessagesRef = FireStore.collection(RoomCollection);

const MessagesQueryByCreateDate =
	MessagesRef.orderBy("createdAt").limitToLast(25);

const getUnixTimestamp = () => {
	return moment().unix();
}
async function createMessage(roomId, messageObject) {
	// message id
	updateRoomTime(roomId);
	const message = {
		...messageObject
	}

	const roomMessageCollection = RoomCollection + "/" + roomId + "/messages";

	const res = await FirestoreCrud.createDocument(roomMessageCollection, message);


}

async function createEnvelope(envelopeObject) {

	const message = {
		...envelopeObject
	}


	const res = await FirestoreCrud.createDocument(EnvelopCollection, message);

	return res;
}





async function addMoneyToMsg(roomId, id, amount) {
	const roomMessageCollection = RoomCollection + "/" + roomId + "/messages";

	const updateData = {
		amount: amount,
		updateAt: getUnixTimestamp(),
	};
	const documentRef = await FirestoreCrud.updateDocumentByIdField(roomMessageCollection, "id", id, updateData);
	// return documentRef;
	//  console.log(documentRef);
}

async function updateRoomTime(roomId) {

	const updateData = {
		updateAt: getUnixTimestamp(),
	};
	const documentRef = await FirestoreCrud.updateDocument(RoomCollection, roomId, updateData);

	console.log(documentRef);
}



async function createChatRoom(chatRoom) {
	// message id

	const chatDoc = {
		...chatRoom
	}
	const res = await FirestoreCrud.createDocument(RoomCollection, chatDoc);

	const data = {
		id: res.id,
	};
	const documentRef = await FirestoreCrud.updateDocument(RoomCollection, res.id, data);

	return res;
}

async function getChatRoomDetail(linkId) {
	const data = await FirestoreCrud.readDocument(RoomCollection, linkId);

	const matchedDocs = data.data() ?? null;
	if (matchedDocs) {
		matchedDocs.docId = data.id;
	}
	return matchedDocs;
}


async function addUserToChatRoom(linkId, user, member) {


	const updateData = {
		member: member + 1,
		participants: arrayUnion(user),
		updateAt: getUnixTimestamp(),
	};
	const temp = await FirestoreCrud.updateDocument(RoomCollection, linkId, updateData);
	console.log(temp);

}

// const getRoomsByUser = async (uuid:string) => {
// 	const user = { uid: uuid };
// 	const queries = [{ field: 'participants', condition: 'array-contains', value: user }];

//     return await FirestoreCrud.createQuery({collection:RoomCollection,queries:queries});
// 	// console.log(a);
// 	// return a;

// };

const getRoomsByUser = (userId) => {
	const user = { uid: userId };
	// console.log("user", user);
	// const q = query(MessagesRef, where("participants", "array-contains", user));
	// const q = query(MessagesRef, where("createdBy", "==", user.uid));
	if (userId) {
		const MessagesRef = FireStore.collection(RoomCollection);
		return MessagesRef.where("participants", "array-contains", user).where('member', '==', 2);
	}

};


const getCreatedRoomsLink = (userId) => {
	const user = { uid: userId };
	// console.log("user", user);
	// const q = query(MessagesRef, where("participants", "array-contains", user));
	// const q = query(MessagesRef, where("createdBy", "==", user.uid));
	if (userId) {
		const MessagesRef = FireStore.collection(RoomCollection);
		return MessagesRef.where("participants", "array-contains", user).where('member', '==', 1);
	}
};

/* ---------------------------------- test ---------------------------------- */
const getLastNMessages = async (roomId) => {
	try {
		const roomMessageCollection = FireStore.collection(RoomCollection).doc(roomId);

		const querySnapshot = await roomMessageCollection
			.collection('messages')
			.orderBy('createdAt', 'desc')
			.limit(1)
			.get();

		if (!querySnapshot.empty) {
			const latestMessage = querySnapshot.docs[0].data();
			console.log("Latest message", latestMessage);
			return latestMessage;
		} else {
			console.log("No messages found");
			return null;
		}
	} catch (error) {
		console.error("Error fetching the latest message: ", error);
	}
};

const getUnreadMessageCount = async (roomId, userId) => {
	try {
		console.log("userId", userId);
		const roomMessageCollection = FireStore.collection(RoomCollection).doc(roomId);

		const querySnapshot = await roomMessageCollection
			.collection('messages')
			.where('unread', '==', true) // Filter only unread messages
			// .where('senderId','!=', userId)
			.get();

		// console.log("querySnapshot---",querySnapshot);
		const unreadMessageCount = querySnapshot.size;
		//   console.log("Unread message count:", unreadMessageCount);
		return unreadMessageCount;
	} catch (error) {
		console.error("Error fetching unread message count: ", error);
	}
};

async function getUsersRooms(uid) {
	const ChatRoomRef = FireStore.collection("rooms");
	const data = await ChatRoomRef.get();
	const matchedDocs = data.size;
	const roomsList = [];
	if (matchedDocs) {
		data.docs.forEach((doc) => {
			const room = doc.data();
			const isFound = room.participants.find((x) => x.uid == uid);
			if (isFound) {
				room.id = doc.id;
				roomsList.push(room);
			}
		});
	}
	return roomsList;
}

const getMessageByRoom = (roomId) => {
	//  const roomMessageCollection=  RoomCollection + "/" + roomId

	const query = MessagesRef.doc(roomId)
		.collection("messages")
		.orderBy("createdAt")
		.limitToLast(25);
	return query;


};

const getEnvelopeDetail = async (id) =>{
	// let data = await EnvelopRef.doc(id).get();
	const querySnapshot = await EnvelopRef.where('id', '==', id).get();
	let data = null;
	if (!querySnapshot.empty) {
		querySnapshot.forEach(doc => {
			console.log(doc);
			data = doc.data();
			data.docId = doc.id;
		});
	} else {
	}

	return data;

}

const getEnvelopeQuery = (senderId) => {
	// Assuming EnvelopRef points to the parent collection
	// return EnvelopRef.where('senderId', '==', senderId);
	return EnvelopRef.where("participants", "array-contains", senderId)

};

const addUserToEnvelope = async (linkId, user, member) =>{


	const updateData = {
		member: member + 1,
		participants: arrayUnion(user),
		updateAt: getUnixTimestamp(),
		unread:false,
	};
	const temp = await FirestoreCrud.updateDocument(EnvelopCollection, linkId, updateData);
	console.log(temp);

}


const updateClaimEnvelope = async (envelope) =>{

	let envelopeDetail = await getEnvelopeDetail(envelope.id);
	console.log("envelopeDetail");
	console.log(envelopeDetail);
	if(envelopeDetail){
		const updateData = {
			updateAt: getUnixTimestamp(),
			isAmtClaim:true,
		};
		const temp = await FirestoreCrud.updateDocument(EnvelopCollection, envelopeDetail.docId, updateData);
	}

}


export {
	MessagesRef,
	MessagesQueryByCreateDate,
	createMessage,
	getLastNMessages,
	createChatRoom,
	getChatRoomDetail,
	addUserToChatRoom,
	getCreatedRoomsLink,
	getUsersRooms,
	getMessageByRoom,
	getRoomsByUser,
	getUnreadMessageCount,
	addMoneyToMsg,
	createEnvelope,
	getEnvelopeQuery,
	getEnvelopeDetail,
	addUserToEnvelope,
	updateClaimEnvelope
};