

import {
  addDoc,
  doc,
  getDoc,
  collection as firestoreCollection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  updateDoc,
  deleteDoc
} from 'firebase/firestore';
import { FireStore } from '../firebase';

 const firestore = FireStore;
//  import { FirebaseFirestore } from '@firebase/firestore-types'; // Add this import

//  const firestore: FirebaseFirestore = FirebaseConfig.FireStore;

 interface QueryConstraint {
    field: string;
    condition: any;
    value: any;
  }
  
  interface ReadDocumentsOptions {
    collection: string;
    queries?: QueryConstraint[];
    orderByField?: string;
    orderByDirection?: any;
    perPage?: number;
    cursorId?: string;
  }

  interface ReadQueryDocumentsOptions {
    collection: string;
    queries?: QueryConstraint[];
  }
  
  const createDocument = async (collection: string, document: any) => {
    return addDoc(firestoreCollection(firestore, collection), document);
  };
  
  const readDocument = (collection: string, id: string) => {
    return getDoc(doc(firestoreCollection(firestore, collection), id));
  };
  
  const readDocuments = async ({
    collection,
    queries,
    orderByField,
    orderByDirection,
    perPage,
    cursorId,
  }: ReadDocumentsOptions) => {
    const collectionRef = firestoreCollection(firestore, collection);
    const queryConstraints : any =[];
  
    if (queries && queries.length > 0) {
      for (const query of queries) {
        queryConstraints.push(where(query.field, query.condition, query.value));
      }
    }
  
    if (orderByField && orderByDirection) {
      queryConstraints.push(orderBy(orderByField, orderByDirection));
    }
  
    if (perPage) {
      queryConstraints.push(limit(perPage));
    }
  
    if (cursorId) {
      const document = await readDocument(collection, cursorId);
      queryConstraints.push(startAfter(document));
    }
  
    const firestoreQuery = query(collectionRef, ...queryConstraints);
    return getDocs(firestoreQuery);
  };

  const readQueryDocuments = async ({
    collection,
    queries,
  }: ReadQueryDocumentsOptions) => {
    const collectionRef = firestoreCollection(firestore, collection);
    const queryConstraints : any =[];
  
    if (queries && queries.length > 0) {
      for (const query of queries) {
        queryConstraints.push(where(query.field, query.condition, query.value));
      }
    }
  
    const firestoreQuery = query(collectionRef, ...queryConstraints);
    return getDocs(firestoreQuery);
  };

  const createQuery = async ({
    collection,
    queries,
  }: ReadQueryDocumentsOptions) => {
    // const collectionRef = firestoreCollection(firestore, collection);
    // let collectionRef = firestore.collection(collection);

    // collectionRef.where("participants", "array-contains",{uid:"5uM6I1hoUtWCpMJqf5eN"});
  //   queries?.forEach(query => {
  //     console.log("query:-",query);
  //     collectionRef.where("participants", "array-contains", query.value);
  //     // collectionRef = collectionRef.where(query.field, query.condition, query.value);
  //     console.log("field:-",query.field);
  //     console.log("condition:-",query.condition);
  //     console.log("value:-",query.value);
      
  // });
  // console.log("collectionRef:--",collectionRef);
      // return collectionRef;
    // console.log("collectionRef");
    // const queryConstraints : any =[];
  
    // if (queries && queries.length > 0) {
    //   for (const query of queries) {
    //     queryConstraints.push(where(query.field, query.condition, query.value));
    //     console.log("queryConstraint:",queryConstraints);
    //   }
    // }
  
    // const firestoreQuery = query(collectionRef, ...queryConstraints);
    // console.log("firestoreQuery:",firestoreQuery);
    // return firestoreQuery;

  };
  
  const updateDocument = (collection: string, id: string, document: any) => {
    return updateDoc(doc(firestoreCollection(firestore, collection), id), document);
  };
  
  const deleteDocument = (collection: string, id: string) => {
    return deleteDoc(doc(firestoreCollection(firestore, collection), id));
  };


  const updateDocumentByIdField = async (collection: string, idFieldName: string, idValue: string, updatedData: any) => {
    // Query for the document based on the id field
    const querySnapshot = await getDocs(
      query(firestoreCollection(firestore, collection), where(idFieldName, '==', idValue))
    );
  
    // Check if a document matching the query is found
    if (querySnapshot.size > 0) {
      // Assuming you only have one document matching the query
      const documentSnapshot = querySnapshot.docs[0];
  
      // Update the document with the provided data
      await updateDoc(documentSnapshot.ref, updatedData);
      console.log('Document updated successfully!');
    } else {
      console.error('Document with specified id not found.');
    }
  };


const FirestoreCrud = {
  createDocument,
   readDocuments,
   readQueryDocuments,
   createQuery,
   readDocument,
  updateDocument,
  deleteDocument,
  updateDocumentByIdField
};

export default FirestoreCrud;
