const FirebaseKalibaName = 'kalibaBosDb';
const FirebaseKalibaDemoConfig = {
  apiKey: "AIzaSyD6LhgRsy7c5zClwB7SGKsS3i5jCXseyP8",
  authDomain: "dragonmail.firebaseapp.com",
  projectId: "dragonmail",
  storageBucket: "dragonmail.appspot.com",
  messagingSenderId: "1088376193074",
  appId: "1:1088376193074:web:ed0ce7bae4d07920723c2b"
};


export const FirebaseConfig = FirebaseKalibaDemoConfig;

export const FirebaseName = FirebaseKalibaName;

// export const collectionPrefix = "preprod-"

export const collectionPrefix = "mainnet-"