import React, { useEffect, useState, useMemo } from "react";
import CurrencyComponent from "../../Components/Currency/CurrencyComponent";
import IceBreakLogo from "../../assets/logo.svg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Alert from "@mui/material/Alert";
import copySvg from "../../assets/copyIcon.svg";
import shareIcon from "../../assets/shareIcon.svg";

import checkSign from "../../assets/checkSign.svg";
import Typography from "@material-ui/core/Typography";

import "./../common/icebreak.css";

import "./MainPage.css";
import { v4 as uuidv4 } from "uuid";
import * as nearAPI from "near-api-js";
import Big from "big.js";
import {
  createConversation,
  getConversationByAccountId,
  getCurrencyRate,
  isNearWallet,
  sendMessage,
  getMessageByConversationId,
  getConversationById,
  updateConversation,
  deleteConversation,
  sendEmail,
} from "../../api/node-api";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import claimsIcons from "../../assets/checkSign.svg";

import messageIcon from "../../assets/messageIcon.svg";

import { useForm } from "react-hook-form";

import { FRONT_END_BASE_URL } from "../../constants/serverConfig";
import Snackbar from "@mui/material/Snackbar";
import "./../common/icebreak.css";
// import "./demo.css";
import { useInterval } from 'ahooks';


import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import { useNavigate } from "react-router-dom";

import {
  MainContainer,
  Message,
  Sidebar,
  ConversationList,
  Conversation,
  Avatar,
  ChatContainer,
  ConversationHeader,
  MessageGroup,
  MessageList,
  MessageInput,
  TypingIndicator,
  InfoButton,
  EllipsisButton,
} from "@chatscope/chat-ui-kit-react";

import "./../common/library.css";

//

//Including all libraries, for access to extra methods.
// var CryptoJS = require("crypto-js");

//   // Encrypt
//   var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
//   //log encrypted data
//   console.log('Encrypt Data -')
//   console.log(ciphertext);

//   // Decrypt
//   var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
//   var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

//   //log decrypted Data
//   console.log('decrypted Data -')
//   console.log(decryptedData);

const GAS_FEE = Big(3)
  .times(10 ** 13)
  .toFixed();

function getStorageValue(key, defaultValue) {
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

function MainPage({
  wallet,
  account,
  nearConfig,
}) {

  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  }, [wallet.isSignedIn]);

  const checkUser = async () => {
    wallet.getBalance();
    if (!wallet.isSignedIn) {
      navigate("/");
    }
  };


  const [currentUser, setCurrentUser] = useState({
    "accountId": wallet.accountId,
    "balance": '0',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(currentUser);

  }, [currentUser]);


  const [width, setWindowWidth] = useState(0);

  const [isChatView, setIsChatView] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    window.scrollTo(10, 10);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    if (width < 640) setIsMobileView(true);
    else setIsMobileView(false);
    setWindowWidth(width);
  };

  //---
  const [user, setUser] = useState(() => {
    getStorageValue("outcome", "null");
    return getStorageValue("user", "null");
  });


  const [seletedConversation, setSeletedConversation] = useState(null);

  const [recentUsers, setRecentUsers] = useState([]);

  const fetchConversation = async () => {
    try {
      const req = { account_id: currentUser.accountId };
      const response = await getConversationByAccountId(req);

      // // Sort the array by timestamp (in ascending order)
      //  response.data.sort((a, b) => b.created_at > a.created_at);


      response.data.sort((a, b) => new Date(a.created_at).toISOString().localeCompare(new Date(b.created_at).toISOString()));

      // // Add a "tag" property to each element in the array based on its index
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].recipient_account_id == undefined) {
          response.data[i].chatName = "Conversation " + (i + 1);
        } else {
          response.data[i].chatName = response.data[i].recipient_account_id;
        }

      }
      response.data.sort((a, b) => new Date(b.update_at).toISOString().localeCompare(new Date(a.update_at).toISOString()));

      setRecentUsers(response.data);

      if (seletedConversation == null) {
        loadDefaultChat(response.data[0]);
      }

      setLoadData(true);
    } catch (error) {
      // setLoadData(false);
    }
  };

  const handleReadRewardInput = (e) => {
    setReadReward(e.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [linkDropReward, setIinkDropReward] = useState("");

  const [linkDropUSDReward, setIinkDropUSDReward] = useState("");

  const handleSetLinkDropRewardInput = () => {
    let usdReward = document.getElementById("linkDropRewardId").value.trim();

    setIinkDropUSDReward(usdReward);

    setIinkDropReward((usdReward / usdPrice).toFixed(2));
  };



  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [mobileBody, setMobileBody] = React.useState(true);

  const [rewardModel, setRewardModel] = React.useState(false);

  const [messages, setMessages] = useState([]);
  const [currentTypeMessage, setCurrentTypeMessage] = useState("");

  const [chatName, setChatName] = useState("");
  const [readReward, setReadReward] = useState("");

  const [isNewConverstion, setIsNewConverstion] = useState(false);

  const [createMessage, setCreateMessage] = useState("");
  const [replyReward, setReplyReward] = useState("");
  const [icebreaker, setIcebreaker] = useState("");
  const [userBalance, setUserBalance] = useState("0.00");
  const [usdPrice, setUsdPrice] = useState(false);
  const [link, setlink] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [linkPopUp, setLinkPopUp] = useState(false);
  const [claimPopUp, setClaimPopUp] = useState(false);
  const [loadLoadData, setLoadData] = useState(false);
  const [signOutMode, setSignOutMode] = useState(false);

  const [shareModelOpen, setShareModelOpen] = useState(false);
  const [shareModelData, setShareModelData] = useState();

  const [isInit, setIsInit] = useState(false);

  const [privateKey, setPrivateKey] = useState("");

  const loadMessage = async (conversation_id) => {
    const req = { conversation_id: conversation_id };
    let msgs = await getMessageByConversationId(req);
    setMessages(msgs.data ?? []);

  };

  const handleShareModelClose = () => {
    setShareModelOpen(false);
  };

  const showError = () => {
    //  <Alert severity="error">This is an error alert — check it out!</Alert>
    alert("Transaction is Rejected");
  };

  const createShareLinkModel = (conversationId) => {
    setLinkPopUp(true);
    setlink(
      FRONT_END_BASE_URL + "recipientlanding" + "?share=" + conversationId
    );
  };

  const sendReply = async (currentMessages) => {
    try {
      let resUP = await updateConversation(seletedConversation);

    } catch (e) {

    }



    let res = await sendMessage(currentMessages);

    if (res.status) {

      setLoadData(!loadLoadData);
      return true;
    }
    return false;
  };

  const loadConversationById = async (conversationId) => {
    const req = { conversation_id: conversationId };
    let conversation = await getConversationById(req);

    if (conversation.data.length == 0) {
      redirectToError();
    }

    loadDefaultChat(conversation.data[0]);
  };

  const checkTheShareCode = async () => {
    const shareCode = localStorage.getItem("shareCode");

    if (shareCode != "NA") {
      localStorage.setItem("shareCode", "NA");

      const req = { conversation_id: shareCode };
      let conversation = await getConversationById(req);
      if (conversation.data.length == 0) {
        redirectToError();
      }

      if (conversation.data[0]["recipient_account_id"] == undefined) {
        conversation.data[0]["recipient_account_id"] = currentUser.accountId;
        await updateConversation(conversation.data[0]);
        window.location.assign("/");
      }

      // const req = { conversation_id: shareCode };
      // let conversation = await getConversationById(req);

      // if (conversation.data.length == 0) {
      //   redirectToError();
      // }

      // loadDefaultChat(conversation.data[0]);
      // conversation.data[0]['recipient_account_id'] = currentUser.accountId
      // let re  = await updateConversation(conversation.data[0]);

      // loadConversationById(shareCode);
    }
  };

  const redirectUrlFromClam = async (conversationId) => {
    loadConversationById(conversationId);
  };

  const redirectToError = async () => {
    window.location.assign("/");
  };

  // useEffect(() => {
  //   fetchConversation();
  // }, [loadLoadData]);


  const fetchTokenPrice = async () => {
    let usdPrice = await getCurrencyRate("near");
    setUsdPrice(Number.parseFloat(usdPrice).toFixed(2));
  };



  useInterval(() => {
    fetchTokenPrice()
  }, 1000);


  const updateBalance = async () => {
    let nearBalance = await wallet.getBalance();
    let tempUser = {
      accountId: currentUser.accountId,
      balance: nearBalance,
    }
    setCurrentUser(tempUser);
    console.log(tempUser);
  };

  useEffect(() => {

    // 0x4227

    updateBalance();

    // // Update the document title using the browser API



    // let bal = nearAPI.utils.format.formatNearAmount(
    //   currentUser.balance.amount.toString(),
    //   4
    // );
    // // let bal = nearAPI.utils.format.formatNearAmount(
    // //   0,
    // //   4
    // // );
    // fecthTokenPrice();

    // checkUser();
    // setUserBalance(Number.parseFloat(bal).toFixed(2));

    // const urlParams = new URLSearchParams(window.location.search);
    // let txhash = urlParams.get("transactionHashes");
    // let errorCode = urlParams.get("errorCode");
    // let converstionId = urlParams.get("converstionId");

    // if (errorCode == "userRejected") {
    //   let lastConverstation = getStorageValue("lastConverstationCreate");
    //   deleteConversation(lastConverstation._id);

    //   // showError()
    //   window.history.pushState("", "", "/mainpage");
    // } else if (txhash != undefined) {
    //   loadLastTxt(txhash);
    // }

    // if (converstionId != null) {
    //   redirectUrlFromClam(converstionId);
    // }
    // checkTheShareCode();
  }, []);

  const updateTransactionHash = async (converstation, txhash) => {
    converstation["se_tx_hash"] = txhash;
    await updateConversation(converstation);
    loadConversationById(converstation.conversation_id);
    createShareLinkModel(converstation.conversation_id);

    window.history.pushState("", "", "/mainpage");
  };

  const loadLastTxt = async (txhash) => {
    const provider = new nearAPI.providers.JsonRpcProvider({
      url: nearConfig.nodeUrl,
    });
    const transaction = await provider.txStatus(txhash, "unnused");
    const data = nearAPI.providers.getTransactionLastResult(transaction);

    const { transaction_outcome: txo, status } = transaction;

    // http://localhost:1234/mainpage?transactionHashes=3Qqq5xmebbRZKh69TwnynUR1PjFAuVtKdFyyLLS8xAmR

    let lastConverstation = getStorageValue("lastConverstationCreate");
    if (lastConverstation != null) {
      //const senderConversation = { 'se_tx_hash': txhash  , 'conversation_id' :lastConverstation.conversation_id};
      updateTransactionHash(lastConverstation, txhash);
    }

    //  window.history.pushState("", "", '/mainpage');
  };

  const loadDefaultChat = (lastConverstation) => {
    setSeletedConversation(lastConverstation);
    loadMessage(lastConverstation.conversation_id);
    setChatName(
      lastConverstation.recipient_account_id == null
        ? lastConverstation.conversation_id.slice(9, 13)
        : currentUser.accountId == lastConverstation.owner_account_id
          ? lastConverstation.recipient_account_id.replace(".testnet", "")
          : lastConverstation.owner_account_id
    );
  };

  const openRewardModel = async (msg) => {
    setCurrentTypeMessage(msg);

    if (msg == "") {
      // alert("")
      setSnackbarSeverity("error");
      setSnackbarMessage("blank message cannot be send");
      setSnackbarOpen(true);
    } else {
      setRewardModel(true);
    }
  };

  // Sending the funds to Icebreak Account and Register Message with database
  const handleLinkCreate = async () => {

    if (linkDropReward == undefined) {
      // alert("")
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter the Reward");
      setSnackbarOpen(true);
    } else if (isNaN(linkDropReward)) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter Proper Number");
      setSnackbarOpen(true);
    } else if (Number(linkDropReward) > Number(userBalance - 0.01)) {
      // alert("")
      setSnackbarSeverity("error");
      setSnackbarMessage("Insufficient funds");

      setSnackbarOpen(true);
    } else if (Number(linkDropReward) < 2) {
      // alert("")
      setSnackbarSeverity("error");
      setSnackbarMessage("Minmum 2 Near for sending the Message");
      setSnackbarOpen(true);
    } else {
      setLoading(true);
      let keyPair = nearAPI.KeyPair.fromRandom("ed25519");
      let conversationID = keyPair.publicKey.toString().replace("ed25519:", "");
      let conversation = {
        conversation_id: conversationID,
        private_key: keyPair.secretKey.toString(), // encript this as well
        owner_account_id: currentUser.accountId,
        reply_reward: linkDropReward,
      };

      createConversation(conversation).then(async (res) => {
        if (res.status) {
          localStorage.setItem(
            "lastConverstationCreate",
            JSON.stringify(res.data)
          );

          let currentMessages = {
            message: currentTypeMessage,
            conversation_id: res.data.conversation_id,
            owner_account_id: conversation.owner_account_id,
          };

          setSeletedConversation(res.data);

          let resR = await sendReply(currentMessages);

          if (resR) {
            setRewardModel(false);
            // contract.send(
            //     { public_key: conversation.conversation_id },
            //     GAS_FEE,
            //     Big(linkDropReward || '0').times(10 ** 24).toFixed()
            // );
            // let contractRes = await contract
            //   .send(
            //     { public_key: conversation.conversation_id },
            //     GAS_FEE,
            //     Big(linkDropReward || "0")
            //       .times(10 ** 24)
            //       .toFixed()
            //   )
            //   .then((e) => {
            //   });

            let functionCallResult = await account.functionCall({
              contractId: nearConfig.contractName,
              methodName: "send",
              args: { public_key: conversation.conversation_id },
              gas: GAS_FEE, // optional param, by the way
              attachedDeposit: Big(linkDropReward || "0")
                .times(10 ** 24)
                .toFixed(),
              walletMeta: "", // optional param, by the way
              walletCallbackUrl: "", // optional param, by the way
            });

            if (
              functionCallResult &&
              functionCallResult.transaction &&
              functionCallResult.transaction.hash
            ) {

              setSnackbarSeverity("success");
              setSnackbarMessage("Message send successfully");
              setSnackbarOpen(true);
              updateTransactionHash(
                conversation,
                functionCallResult.transaction.hash
              );
              // setTransactionHash(functionCallResult.transaction.hash);
            }
          } else {
            setSnackbarSeverity("error");
            setSnackbarMessage("Please Try again later...");
            setSnackbarOpen(true);
            // can delete the create conversation here
          }
        } else {
          setSnackbarSeverity("error");
          setSnackbarMessage("Please Try again later..");
          setSnackbarOpen(true);
        }
      });

      setLoading(false);
    }

    // ed25519: FERQgg1LtZHxnjCBYDnufjENrz8KQbKjRwVPgJkfJyBA
    //  5P44npDkCY2SF14BHHjYdjSU7M15YSFo9q2KftAiNMR2eFLaFXfD8taUb97mtCtGAycE6MQXCvacuvKuXaJXqBF2
    //  const NO_DEPOSIT = '3000000000000000000000000';
  };

  const handleIceBreakerInput = (e) => {
    setIcebreaker(e.target.value);
  };

  const handleNewChat = () => {
    window.location.reload();
  };

  const messageInputField = (msg) => {
    // var requiredDiv =  //document.getElementById("sendMessageInput").value;
    if (msg.length == 0) {
      setSnackbarSeverity("error");
      setSnackbarMessage("blank message cannot be send");
      setSnackbarOpen(true);
      return false;
    } else {
      let currentMessages = {
        message: msg,
        conversation_id: seletedConversation.conversation_id,
        owner_account_id: currentUser.accountId,
      };

      let resR = sendReply(currentMessages);
      if (resR) {
        setMessages((prev) => {
          return [...prev, currentMessages];
        });

        // if (messages.length == 0) {
        //     // setShareModelOpen(true);
        //     createShareLinkModel();
        // }

        recentUsers.map(async (re) => {
          if (re.conversation_id == seletedConversation) {
            re["messages"] = [...re["messages"], currentMessages];
          }
        });

        /*
                check for the clam
                */

        if (
          seletedConversation.owner_account_id != currentUser.accountId &&
          !seletedConversation.isRepled
        ) {
          // setShareModelOpen(true);
          setClaimPopUp(true);
          (seletedConversation.isRepled = true),
            updateConversation(seletedConversation);
        }

        // document.getElementById("sendMessageInput").value = ""
        setCurrentTypeMessage("");
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage("Please Try again later...");
        setSnackbarOpen(true);
        // can delete the create conversation here
      }
    }
  };
  const handelSendMessage = (msg) => {
    setCurrentTypeMessage(msg);
    messageInputField(msg);
  };

  const handleKeyDown = (e) => {
    var currentMessages = {};

    if (e.key === "Enter") {
      // messageInputField();
      seletedConversation != null && !isNewConverstion
        ? handelSendMessage()
        : openRewardModel();
    }
  };

  const sendRewardMessage = async () => {
    let currentMessages = {
      message: document.getElementById("readRewardMessage").value,
      conversation_id: shareModelData.conversation_id,
      owner_account_id: currentUser.accountId,
    };

    let resR = sendReply(currentMessages);
    if (resR) {
      (shareModelData["recipient_account_id"] = currentUser.accountId),
        await updateConversation(shareModelData);

      setShareModelOpen(false);

      fetchConversation();
    } else {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please Try again later...");
      setSnackbarOpen(true);
      // can delete the create conversation here
    }
  };

  const isMobile = window.innerWidth <= 500;

  const formateUserName = (userName) => {
    let formatedName = userName;
    if (userName.length > 18) {
      formatedName = userName.slice(0, 2) + "..." + userName.slice(-4);
    }
    return formatedName.replace(".testnet", "");
  };

  const getUser = (userConversation) => {
    return {
      avatar: "",
      username:
        userConversation.recipient_account_id == null
          ? userConversation.chatName
          : currentUser.accountId == userConversation.owner_account_id
            ? formateUserName(userConversation.recipient_account_id)
            : formateUserName(userConversation.owner_account_id),


    };
  };

  // Get current user data
  const [currentUserAvatar, currentUserName] = useMemo(() => {
    if (seletedConversation) {
      const participant = seletedConversation;

      if (participant) {
        const user = getUser(participant);
        if (user) {
          return [
            <Avatar
              style={{
                width: 30,
                height: 30,
                background: "rgba(0, 86, 98, 0.51)",
                justifyContent: "center",
                display: "flex",
                letterSpacing: 0,
                lineHeight: 2,
                color: "#FFFFFF",
              }}
            >
              {user?.username?.slice(0, 2).toUpperCase()}{" "}
            </Avatar>,
            user.username,
          ];
        }
      }
    }

    return [undefined, undefined];
  }, [setSeletedConversation, getUser]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const sideBar = () => {
    return (
      <Sidebar
        position="left"
        scrollable
        style={{
          // height: isMobile ? "80vh" : "95vh",
          // height:window.innerHeight,

          width: window.innerWidth,

          overflow: "hidden",
          border: "#66B6C0",
          backgroundColor: "rgba(172, 215, 219, 0.3)",
          borderRadius: "15px 15px 15px 15px",
          border: "rgba(172, 215, 219, 0.3)",
        }}
      >
        <ConversationHeader
          style={{
            borderRadius: "15px 15px 0px 0px",
            border: "#66B6C0",
            backgroundColor: "#66B6C0",
          }}
        >
          <Avatar src={IceBreakLogo} />
          <ConversationHeader.Content style={{ color: "#FFFFFF" }}>
            {currentUser.accountId}
          </ConversationHeader.Content>
          {
            <ConversationHeader.Actions>
              {isMobile && (
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                  }}
                >
                  <CurrencyComponent
                    balance={userBalance}
                    usdPrice={usdPrice}
                    handleSignOut={() => { wallet.signOut(); navigate("/"); }}
                  />
                </div>
              )}{" "}
              <EllipsisButton orientation="vertical" onClick={handleClick} />
            </ConversationHeader.Actions>
          }
        </ConversationHeader>
        <ConversationList
          style={{
            backgroundColor: "rgba(172, 215, 219, 0.3)",
          }}
        >
          {recentUsers.map((c) => {
            // Helper for getting the data of the first participant
            const [avatar, name] = (() => {
              const participant = c != null ? c : undefined;

              if (participant) {
                const user = getUser(participant);
                if (user) {
                  return [
                    <Avatar
                      style={{
                        width: 30,
                        height: 30,
                        background: "rgba(0, 86, 98, 0.51)",
                        justifyContent: "center",
                        display: "flex",
                        letterSpacing: 0,
                        lineHeight: 2,
                        color: "#FFFFFF",
                      }}
                    >
                      {participant.recipient_account_id == null ? user.username.slice(-2).toUpperCase() : user.username.slice(0, 2).toUpperCase()}{" "}
                    </Avatar>,
                    user.username,
                  ];
                }
              }

              return [undefined, undefined];
            })();

            return (
              <Conversation
                style={{ backgroundColor: "rgba(172, 215, 219, 0.0)" }}
                key={c.conversation_id}
                name={name}
                info={
                  c.draft
                    ? `Draft: ${c.draft
                      .replace(/<br>/g, "\n")
                      .replace(/&nbsp;/g, " ")}`
                    : ``
                }
                active={false}
                // unreadCnt={c.unreadCounter}
                onClick={() => {
                  setIsNewConverstion(false);
                  setSeletedConversation(c);
                  loadMessage(c.conversation_id);
                  setIsChatView(true);
                }}
              >
                {avatar}
              </Conversation>
            );
          })}
        </ConversationList>
        <ConversationHeader
          style={{
            border: "rgba(172, 215, 219, 0.3)",
            backgroundColor: "rgba(172, 215, 219, 0.3)",
            borderRadius: "0px 0px 15px 15px",
          }}
        >
          {/* <Avatar src={IceBreakLogo} /> */}
          <ConversationHeader.Content style={{ color: "#FFFFFF" }}>
            <div
              onClick={() => {
                setMobileBody(false);
                setIsNewConverstion(true);
                setIsChatView(true);
              }}
              style={{
                cursor: "pointer",
                background:
                  "linear-gradient(270deg, #ACD7DB 5.21%, #4FB3BF 49.48%, #00838F 100%)",
                borderRadius: "30px",
                padding: "10px",
                textAlign: "center",
              }}
            >
              New Message
            </div>
          </ConversationHeader.Content>
        </ConversationHeader>
      </Sidebar>
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const chatBox = () => {
    return (
      <ChatContainer
        style={{
          width: window.innerWidth,
          // padding:1,
          overflow: "hidden",
          background: "rgba(172, 215, 219, 0.1)",
          borderRadius: "15px 15px 15px 15px",
        }}
      >
        <ConversationHeader
          style={{
            borderStartEndRadius: "15px",
            borderTopLeftRadius: "15px",
            border: "#66B6C0",
            backgroundColor: "#66B6C0",

            color: "#FFFFFF",
          }}
        >
          <ConversationHeader.Back
            style={{ color: "black" }}
            onClick={() => {
              setIsChatView(false);
            }}
          />

          {seletedConversation == null || isNewConverstion ? (
            <Avatar src={IceBreakLogo} />
          ) : (
            currentUserAvatar
          )}
          <ConversationHeader.Content

          // userName={
          //   seletedConversation == null || isNewConverstion
          //     ? "New Message"
          //     : currentUserName
          // }
          >
            <Typography align="center">
              {seletedConversation == null || isNewConverstion
                ? "New Message"
                : currentUserName}
            </Typography>
          </ConversationHeader.Content>

          {!isMobile ? (
            <ConversationHeader.Actions>
              {seletedConversation != null &&
                seletedConversation.se_tx_hash != null &&
                seletedConversation.owner_account_id == currentUser.accountId &&
                messages.length > 0 && !isNewConverstion && (
                  <img
                    height={35}
                    width={35}
                    style={{
                      cursor: "pointer",
                    }}
                    src={copySvg}
                    onClick={() => {
                      createShareLinkModel(seletedConversation.conversation_id);
                    }}
                  ></img>
                )}
              {seletedConversation != null &&
                seletedConversation?.owner_account_id !=
                currentUser.accountId &&
                seletedConversation.isRepled && !isNewConverstion && (
                  <img
                    height={35}
                    width={35}
                    style={{
                      cursor: "pointer",
                    }}
                    src={claimsIcons}
                    onClick={() => {
                      setClaimPopUp(true);
                    }}
                  ></img>
                )}
              <div
                style={{
                  cursor: "pointer",
                  marginLeft: 10,
                }}
              >
                {/* <div onClick={() => setSignOutMode(true)}>
                
                </div> */}
                <CurrencyComponent
                  balance={currentUser.balance}
                  usdPrice={usdPrice}
                />
              </div>
            </ConversationHeader.Actions>
          ) : (
            <ConversationHeader.Actions>
              {" "}
              {seletedConversation != null &&
                seletedConversation.se_tx_hash != null &&
                seletedConversation.owner_account_id == currentUser.accountId &&
                messages.length > 0 && !isNewConverstion && (
                  <img
                    height={30}
                    width={30}
                    style={{
                      cursor: "pointer",
                    }}
                    src={copySvg}
                    onClick={() => {
                      createShareLinkModel(seletedConversation.conversation_id);
                    }}
                  ></img>
                )}
              {seletedConversation != null &&
                seletedConversation?.owner_account_id !=
                currentUser.accountId &&
                seletedConversation.isRepled && !isNewConverstion && (
                  <img
                    height={30}
                    width={30}
                    style={{
                      cursor: "pointer",
                    }}
                    src={claimsIcons}
                    onClick={() => {
                      setClaimPopUp(true);
                    }}
                  ></img>
                )}
              <EllipsisButton orientation="vertical" onClick={handleClick} />
            </ConversationHeader.Actions>
          )}
        </ConversationHeader>

        <MessageList
          className="chatBody"
          style={{
            backgroundColor: "rgba(172, 215, 219, 0.3)",
          }}
        // typingIndicator={getTypingIndicator()}
        >
          {seletedConversation &&
            !isNewConverstion &&
            messages.map((g) => (
              <MessageGroup
                style={{}}
                key={uuidv4()}
                direction={
                  g.owner_account_id == currentUser.accountId
                    ? "outgoing"
                    : "incoming"
                }
              >
                <MessageGroup.Messages
                  style={
                    {
                      // backgroundColor:"#FFFFFF",
                      // color:"#FFFFFF",
                    }
                  }
                >
                  {/* {g.messages.map((m:ChatMessage<MessageContentType>) => )} */}
                  <Message
                    key={uuidv4()}
                    style={{
                      // minWidth:150,
                      borderRadius: "25px",
                      textAlign: "end",
                    }}
                    model={{
                      type: "html",
                      payload: g.message,
                      direction:
                        g.owner_account_id == currentUser.accountId
                          ? "incoming"
                          : "outgoing",
                      position: "normal",
                    }}
                  />
                </MessageGroup.Messages>
              </MessageGroup>
            ))}
        </MessageList>
        <MessageInput
          style={{
            textAlign: "end",
            borderEndEndRadius: "15px",
            borderBottomLeftRadius: "15px",

            border: "#66B6C0",
            backgroundColor: "#66B6C0",
          }}
          // value={currentTypeMessage}
          // onChange={(e) => setCurrentTypeMessage(e.target.value)}
          onSend={(msg) => {
            seletedConversation != null && !isNewConverstion
              ? handelSendMessage(msg)
              : openRewardModel(msg);
          }}
          disabled={false}
          attachButton={false}
          placeholder="write something..."
        // autoFocus
        />
      </ChatContainer>
    );
  };

  const getDesktop = () => {
    return (
      <MainContainer
        responsive
        style={{
          backgroundColor: "rgba(196, 196, 196, 0.2)",
          padding: 40,
          height: window.innerHeight,
        }}
      >
        {sideBar()}
        <div style={{ padding: 10 }}></div>
        {chatBox()}
      </MainContainer>
    );
  };

  const getMobile = () => {
    return isChatView ? chatBox() : sideBar();
  };

  //position: -webkit-sticky; /* Safari & IE */
  // position: sticky;
  // top: 0;
  const renderChatScop = () => {
    return (
      <div
        style={{
          // backgroundColor: "rgba(196, 196, 196, 0.2)",
          position: "-webkit-sticky",
          position: "sticky",
          height: isMobile ? "100svh" : "100svh",
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",

          // height:window.innerHeight,
        }}
      >
        {isMobileView ? getMobile() : getDesktop()}
      </div>
    );
  };

  // collecting the clam of user
  const onClaimClick = async () => {
    setLoading(true)
    let keyPair = nearAPI.KeyPair.fromString(seletedConversation.private_key);

    // Step 2:  load up an inMemorySigner using the keyPair for the account
    let signerClam = await nearAPI.InMemorySigner.fromKeyPair(
      nearConfig.networkId,
      nearConfig.contractName,
      keyPair
    );

    nearConfig.deps = { keyStore: signerClam.keyStore };
    let nearClame = await nearAPI.connect({ ...nearConfig });

    let accountClame = new nearAPI.Account(
      nearClame.connection,
      nearConfig.contractName
    );

    try {
      let functionCallResult = await accountClame.functionCall({
        contractId: nearConfig.contractName,
        methodName: "claim",
        args: { account_id: currentUser.accountId },
        gas: GAS_FEE, // optional param, by the way
        attachedDeposit: 0,
        walletMeta: "", // optional param, by the way
        walletCallbackUrl: "", // optional param, by the way
      });

      if (
        functionCallResult &&
        functionCallResult.transaction &&
        functionCallResult.transaction.hash
      ) {

        setSnackbarMessage("claim successfully");
        setSnackbarOpen(true);

        // refresh bal

        // updateTransactionHash(conversation ,  functionCallResult.transaction.hash);
        // setTransactionHash(functionCallResult.transaction.hash);
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage("Please try again after some time");
        setSnackbarOpen(true);
      }
    } catch (e) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please try again after some time");
      setSnackbarOpen(true);
    }

    setLoading(false)

    // jainesh
  };

  // Start
  return (
    <div style={{ fontSize: "1em" }}>
      {" "}
      {/* got the share code */}


      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : renderChatScop()}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem onClick={() => setSignOutMode(true)}>Logout</MenuItem>
      </Menu>
      <Dialog open={shareModelOpen}>
        <DialogTitle>Reply To Get Reward</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>
              {" "}
              You will get{" "}
              {shareModelData != undefined
                ? shareModelData.reply_reward_formate - 1
                : ""}{" "}
              Near to Reply this Message
            </b>
            <br />
            {shareModelData != undefined ? shareModelData.message.message : ""}
          </DialogContentText>
          <DialogContentText>
            Send By :{" "}
            {shareModelData != undefined ? shareModelData.owner_account_id : ""}
          </DialogContentText>

          <TextField
            // autoFocus
            margin="dense"
            id="readRewardMessage"
            label="Message"
            type="text"
            fullWidth
            variant="standard"
          //onKeyDown={handleReadRewardMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShareModelClose}>Cancel</Button>
          <Button onClick={sendRewardMessage}>Send</Button>
        </DialogActions>
      </Dialog>
      {/* Reaward Pop*/}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: "#005662",
            color: "#FFFFFF",
          },
        }}
        open={rewardModel}
        // overlayStyle={{ backgroundColor: "transparent" }}
        style={{ backgroundColor: "transparent" }}
      >
        <DialogTitle>
          <Typography align="center"> Set Icebreaker</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div style={{ position: "relative" }}>
            <div className="reward-input-field">
              {/* <div className="inputIcon" style={{ width: "50px" }}></div> */}
              <input
                type="number"
                min="0"
                placeholder="0.0 USD"
                className="input-field"
                {...register("linkDropReward", { required: true })}
                onChange={handleSetLinkDropRewardInput}
                // placeholder="0.0"
                hint="aofd"
                value={linkDropUSDReward}
                id="linkDropRewardId"
                required
                // autoFocus
                style={{ backgroundColor: "#005662", color: "white" }}
                autoComplete="off"
              />
            </div>
            {usdPrice && (
              <Typography align="center">
                Approx{" "}
                {linkDropUSDReward
                  ? (linkDropUSDReward / usdPrice).toFixed(2)
                  : 0}{" "}
                Near
              </Typography>
            )}
            {usdPrice && (
              <Typography align="center">
                Max{" "}
                {userBalance != 0
                  ? ((userBalance - 0.01)).toFixed(2)
                  : 0}{" "}
                Near
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "space-between",
            paddingLeft: 25,
            paddingRight: 25,
            paddingBottom: 25,
          }}
        >
          <Button
            onClick={() => {
              setRewardModel(false);
            }}
            style={{
              backgroundColor: "#4FB3BF",
              color: "#FFFFFF",
              minWidth: 100,
              borderRadius: 50,
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#4FB3BF",
              color: "#FFFFFF",
              minWidth: 100,
              borderRadius: 50,
            }}
            onClick={handleLinkCreate}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* Clam Pop*/}
      <Dialog
        open={claimPopUp}
        onBackdropClick={() => {
          setClaimPopUp(false);
        }}
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: "#005662",
            color: "#FFFFFF",
          },
        }}
      >
        <DialogTitle>
          <Typography align="center">Thanks for your time</Typography>
        </DialogTitle>
        <DialogContentText alignItems="center" justifyContent="center">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 10,
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height={100}
              width={100}
              style={{
                cursor: "pointer",
                paddingLeft: 20,
                paddingRight: 20,
              }}
              src={checkSign}
            ></img>

            <div
              style={{ color: "#FFFFFF" }}
              onClick={async () => {
                localStorage.setItem(
                  "lastConverstationCreate",
                  JSON.stringify(seletedConversation)
                );
              }}
            >
              <Typography align="center">
                {" "}
                +{" "}
                {seletedConversation && usdPrice
                  ? ((seletedConversation?.reply_reward - 1) * usdPrice).toFixed(2) + " USD "
                  : (seletedConversation?.reply_reward - 1).toFixed(2) + " Near "}
              </Typography>
            </div>
          </div>
        </DialogContentText>

        <DialogActions>
          <DialogActions
            style={{
              justifyContent: "space-between",
              paddingLeft: 25,
              paddingRight: 25,
              paddingBottom: 25,
            }}
          >
            <Button
              onClick={() => {
                setClaimPopUp(false);
              }}
              style={{
                backgroundColor: "#4FB3BF",
                color: "#FFFFFF",
                minWidth: 100,
                borderRadius: 50,
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#4FB3BF",
                color: "#FFFFFF",
                minWidth: 100,
                borderRadius: 50,
              }}
              onClick={
                () => {
                  // openInNewTab(
                  //   "https://wallet.testnet.near.org/linkdrop/" +
                  //     nearConfig.contractName +
                  //     "/" +
                  //     seletedConversation.private_key
                  // );

                  onClaimClick();
                  setClaimPopUp(false);
                }

                // () => openInNewTab('https://wallet.testnet.near.org/linkdrop/' + nearConfig.contractName + '/' + seletedConversation.private_key + '?redirectUrl=' + FRONT_END_BASE_URL + "?converstionId=" + seletedConversation.conversation_id)
              }
            >
              claim
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>
      {/* Sign out and Balance Pop*/}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: "#005662",
            color: "#FFFFFF",
          },
        }}
        open={signOutMode}
      >
        <DialogTitle>Do you want to logout</DialogTitle>

        <DialogActions
          style={{
            justifyContent: "space-between",
            paddingLeft: 25,
            paddingRight: 25,
            paddingBottom: 25,
          }}
        >
          <Button
            onClick={() => {
              setSignOutMode(false);
            }}
            style={{
              backgroundColor: "#4FB3BF",
              color: "#FFFFFF",
              minWidth: 100,
              borderRadius: 50,
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#4FB3BF",
              color: "#FFFFFF",
              minWidth: 100,
              borderRadius: 50,
            }}
            onClick={() => { wallet.signOut(); navigate("/"); }}
          >
            Sign out
          </Button>
        </DialogActions>
      </Dialog>
      {/* link pop up */}
      <Dialog
        open={linkPopUp}
        onBackdropClick={() => {
          setLinkPopUp(false);
        }}
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundColor: "#005662",
            color: "#FFFFFF",
          },
        }}
      >
        <DialogTitle titleStyle={{ textAlign: "center" }}>
          <Typography align="center"> Share your message!</Typography>
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogContentText
          alignItems="center"
          justifyContent="center"
          style={{ overflow: "clip" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 10,
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height={80}
              width={80}
              style={{
                cursor: "pointer",
              }}
              src={messageIcon}
            ></img>

            <div style={{ flexDirection: "column" }}>
              <img
                height={40}
                width={40}
                style={{
                  cursor: "pointer",
                  paddingLeft: 10,
                }}
                onClick={async () => {
                  navigator.clipboard.writeText(link);

                  if (navigator.clipboard) {
                    await navigator.clipboard.writeText(link);
                    setSnackbarSeverity("success");
                    setSnackbarMessage("copy successfully");

                    setSnackbarOpen(true);
                    setLinkPopUp(false);
                  } else {
                    alert(link);
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    setSnackbarMessage("copy successfully");
                    setLinkPopUp(false);
                  }
                }}
                src={shareIcon}
              ></img>
              <img
                height={40}
                width={40}
                onClick={async () => {
                  // navigator.clipboard.writeText(link)

                  navigator.clipboard.writeText(link);

                  if (navigator.clipboard) {
                    await navigator.clipboard.writeText(link);
                    setSnackbarMessage("copy successfully");
                    setSnackbarOpen(true);
                    setLinkPopUp(false);
                  } else {
                    alert(link);
                    setSnackbarOpen(true);
                    setSnackbarMessage("copy successfully");
                    setLinkPopUp(false);
                  }
                }}
                style={{
                  cursor: "pointer",
                  paddingLeft: 10,
                }}
                src={copySvg}
              ></img>

              <div
                style={{
                  padding: 10,
                  width: "250px",
                  wordWrap: "break-word",
                  color: "#ffffff",
                  fontSize: 12,

                }}
                onClick={async () => {
                  // navigator.clipboard.writeText(link)

                  if (navigator.clipboard) {
                    await navigator.clipboard.writeText(link);
                    setSnackbarMessage("copy successfully");
                    setSnackbarOpen(true);
                    setLinkPopUp(false);
                  } else {
                    alert(link);
                    setSnackbarOpen(true);
                    setSnackbarMessage("copy successfully");
                    setLinkPopUp(false);
                  }
                }}
              >
                {link}
              </div>
            </div>
          </div>
        </DialogContentText>

        {/* <DialogActions>
          <Button
            onClick={async () => {
              if (navigator.clipboard) {
                await navigator.clipboard.writeText(link);
                setSnackbarMessage("copy successfully");
                setSnackbarOpen(true);
                setLinkPopUp(false);
              } else {
                alert(link);
                setSnackbarOpen(true);
                setSnackbarMessage("copy successfully");
                setLinkPopUp(false);
              }
            }}
          >
            Copy
          </Button>
        </DialogActions>
 */}
      </Dialog>
      {/* MainPage ends */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity={snackbarSeverity}
          sx={{ width: "100%", right: 0 }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MainPage;
