import React, { useState } from 'react'
import './CurrencyComponent.css'
import './../../Pages/common/icebreak.css'
import currencyLogo from '../../assets/currencyLogo.svg'
// import MyNearIconUrl from '@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png';
import MyNearIconUrl from '../../assets/icon-near-white.svg'

function CurrencyComponent({ balance, usdPrice }) {
    // const [bnb, setBnb] = useState(balance)
    // const [usd, setUsd] = useState("25")
    return (
        <div className='currentWrapper'>

            <div className="currencyComponent" style={{
                background: 'linear-gradient(270deg, #66B6C0 0%, #ACD7DB 0.01%, #66B6C0 44.79%, #00838F 100%)',
                border: 1,
                borderColor: 'black'

            }}>
                <div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="H9">
                            {usdPrice != 0 ? (balance * usdPrice).toFixed(2) : '-'} USD

                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>

                        <div className="H10">
                            {balance}

                        </div>
                        <img height={25} width={25} src={MyNearIconUrl} />

                    </div>
                </div>
            </div>


        </div>
    )
}

export default CurrencyComponent
