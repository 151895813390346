/* Talking with a contract often involves transforming data, we recommend you to encapsulate that logic into a class */
import { utils } from 'near-api-js'
const STORAGE_DEPOSIT = '5000000000000000000000000';
const REPLY_DEPOSIT = '5000000000000000000000000';


export class UserInterface {
  constructor({ contractId, walletToUse }) {
    this.contractId = contractId;
    this.wallet = walletToUse;    
  }

}

export class RegistorInterface {
  constructor({ contractId, walletToUse }) {
    this.contractId = contractId;
    this.wallet = walletToUse;    
  }

  async getTreasury() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_treasury" })
  }

  async getRegistrationFee() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_registration_fee" })
  }

  async getCreatedConversationFee() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_created_conversation_fee" })
  }

  

  async getUserName(accountId) {
    return await this.wallet.viewMethod({ contractId: this.contractId, args:{account_id:accountId} ,method: "get_user_name_account" })
  }


  async isUserRegistor(account_id) {
    console.log("Is User Start");
    let isUser =  await this.wallet.viewMethod({ contractId: this.contractId, args:{account_id:account_id} ,method: "is_user_registor" })
    return isUser;
  }

  

  async getUsers(accountId) {
    return await this.wallet.viewMethod({ contractId: this.contractId, args:{userName:accountId} ,method: "get_users" })
  }


  
  async getConvertionAmountFromTransaction(txhash) {
    let convertion_amount = await this.wallet.getConvertionAmountFromTransaction(txhash);
    return utils.format.formatNearAmount(convertion_amount);
  }
  async registerUser(user_name) {
    let deposit =STORAGE_DEPOSIT; //utils.format.parseNearAmount(STORAGE_DEPOSIT).toString();
    let response = await this.wallet.callMethod({ contractId: this.contractId,method: "registor" , args:{user_name:user_name},deposit })
    return response
  }
  

  async createConversation(uuid , replyReward) {
     const amountInYocto = utils.format.parseNearAmount(replyReward.toString());
     let response = await this.wallet.callMethod({ contractId: this.contractId,method: "createConversation" , args:{replyReward:replyReward,uuid:uuid} ,deposit:amountInYocto})
    console.log(response);
    return response
  }
  

  async getConversation() {
 
    let response = await this.wallet.viewMethod({ contractId: this.contractId,method: "get_conversation" , args:{} })
   
    return response
  }

  async replyConversation(uuid){
 
    let response = await this.wallet.callMethod({ contractId: this.contractId,method: "replyConversation" , args:{uuid:uuid} })
    console.log(response);

    response.forEach(elem => {
      console.log(elem);
    });
    return response
  }
  

  


}


export class CreateConvertionInterface {
  constructor({ contractId, walletToUse }) {
    this.contractId = contractId;
    this.wallet = walletToUse;    
  }

  async getTreasury() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: "get_treasury" })
  }

  async getUserName(accountId) {
    return await this.wallet.viewMethod({ contractId: this.contractId, args:{account_id:accountId} ,method: "get_user_name_account" })
  }

  

  async getUsers(accountId) {
    return await this.wallet.viewMethod({ contractId: this.contractId, args:{userName:accountId} ,method: "get_users" })
  }


  
  async getConvertionAmountFromTransaction(txhash) {
    let convertion_amount = await this.wallet.getConvertionAmountFromTransaction(txhash);
    return utils.format.formatNearAmount(convertion_amount);
  }

  
  async createConvertion(message) {
    console.log("inside")
    let deposit =STORAGE_DEPOSIT; //utils.format.parseNearAmount(STORAGE_DEPOSIT).toString();
    let replyReward = STORAGE_DEPOSIT;
    let response = await this.wallet.callMethod({ contractId: this.contractId,method: "createConverstion" , args:{message:message , replyReward:replyReward},deposit })
    return response
  }
}