import React, { useState, useEffect } from "react";
import Modal from "react-modal";
// import './LandingPage.css'
import "./../common/dragonMail.css";
import "./../common/dragonMail.css";
import modalLogo from "../../assets/modalLogo.svg";
import MyNearIconUrl from "@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png";
import { useNavigate } from "react-router-dom";
import * as nearAPI from "near-api-js";
import { v4 as uuidv4 } from "uuid";
import { useCollectionData } from "react-firebase-hooks/firestore";
import messageIcon from "../../assets/messageIcon.svg";
import shareIcon from "../../assets/shareIcon.svg";
import copySvg from "../../assets/copyIcon.svg";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import { createChatRoom, createEnvelope, getEnvelopeQuery, addUserToEnvelope, updateClaimEnvelope } from "./../../api/firebase/MessageFirebaseRef"
import { useInterval } from 'ahooks';
import {
  createConversation,
  getConversationByAccountId,
  getCurrencyRate,
  isNearWallet,
  sendMessage,
  getMessageByConversationId,
  getConversationById,
  updateConversation,
  deleteConversation,
  sendEmail,
} from "../../api/node-api";
import moment from "moment";

import { useForm } from "react-hook-form";
import { useLocalStorage } from "usehooks-ts";
import { borderColor } from "@material-ui/system";


function EnvelopePage({ wallet }) {

  const FT_CONTRACT_ID = "5-ft.dev-1705192023920-59281357047296";

  const navigate = useNavigate();

  const [invitee, setInvitee] = useLocalStorage("invitee", null);


  const [selectedCard, setSelectedCard] = useState(null);


  useEffect(() => {
    if (invitee) {
      addUserToClaimList();
    }
  }, [invitee]);

  const addUserToClaimList = async () => {
    console.log(" call the add new user");
    await addUserToEnvelope(invitee.docId, currentUser.accountId, 1)
    setInvitee(null);
  }


  const claimToken = async () => {
    try {
      setIsLoading(true);
      let res = await wallet.claimToken(selectedCard.token.keyPair.secretKey);
      // if(res){
      //   // 0x4227.testnet
      // }
    } catch (e) {
    }
    await updateClaimEnvelope(selectedCard);
    setSelectedCard(null)
    setIsLoading(false)
  }

  useEffect(() => {
    checkUser();
  }, [wallet.isSignedIn]);


  const checkUser = async () => {

    if (wallet.isSignedIn) {
      navigate("/app");
    } else {
      navigate("/");
    }
  };



  const updateBalance = async () => {
    let bkBalance = await wallet.getBalanceOfCustomToken('blackdragon.tkn.near');

    let nkBalance = await wallet.getBalanceOfCustomToken('ftv2.nekotoken.near');

    let lonkBalance = await wallet.getBalanceOfCustomToken('token.lonkingnearbackto2024.near');

    let shBalance = await wallet.getBalanceOfCustomToken('token.0xshitzu.near');

    let tempUser = {
      accountId: currentUser.accountId,
      balance: 0,
      bkBalance: bkBalance,
      nkBalance: nkBalance,
      lonkBalance: lonkBalance,
      shBalance: shBalance
    }


    setCurrentUser(tempUser);

  };

  const [usdPrice, setUsdPrice] = useState(false);
  const [userBalance, setUserBalance] = useState("0.00");


  const [isLoading, setIsLoading] = useState(false);

  const [isNotif, setIsNotif] = useState(false);

  const [isNotifBlackdragon, setIsNotifBlackdragon] = useState(false);
  const [isNotifNeko, setIsNotifNeko] = useState(false);
  const [isNotifLonk, setIsNotifLonk] = useState(false);
  const [isNotifShitzu, setIsNotifShitzu] = useState(false);

  const chooseTokenNotif = (requestTokenContract) => {
    console.log("chooseTokenNotif", requestTokenContract)
    if (requestTokenContract == "blackdragon.tkn.near") {
      setIsNotifBlackdragon(true);
    } else if (requestTokenContract == "ftv2.nekotoken.near") {
      setIsNotifNeko(true);
    } else if (requestTokenContract == "token.lonkingnearbackto2024.near") {
      setIsNotifLonk(true);
    } else if (requestTokenContract == "token.0xshitzu.near") {
      setIsNotifShitzu(true);
    }
  }

  const setBorderBottom = (notifA, notifB) => {
    let borderBottom = "3px solid #A30010"

    if (notifA == true) {
      borderBottom = "4px solid #D1A251"
    } else if (notifB == true) {
      borderBottom = "transparent"
    }

    return borderBottom;
  }

  const [currentUser, setCurrentUser] = useState({
    "accountId": wallet.accountId,
    "balance": '0',
    'bkBalance': '0',
    'nkBalance': '0',
    'lonkBalance': '0'
  });

  const [createEnvelopModel, setEnvelopModel] = React.useState(false);
  const [shareEnvelopModel, setShareEnvelopModel] = React.useState(false);
  const [openEnvelopModel, setOpenEnvelopModel] = React.useState(false);
  const [openDropdownButton, setOpenDropdownButton] = React.useState(false);
  const [openAccountDropdownButton, setOpenAccountDropdownButton] = React.useState(false);

  const [tokenName, setTokenName] = useState("Select Token");
  const [tokenAmount, setTokenAmount] = useState("Amount");

  // const BaseURL = "http://localhost:1234" + "/share?id=";
  // const BaseURL = "https://preprod-27484.web.app" + "/share?id=";
  const BaseURL = "https://dragonmail.xyz/" + "/share?id=";

  const [linkDropUSDReward, setLinkDropUSDReward] = useState("");
  const [shareLink, setShareLink] = useState("");


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  const fetchTokenPrice = async () => {
    // let usdPrice = await getCurrencyRate("near");
    // setUsdPrice(Number.parseFloat(usdPrice).toFixed(2));
  };


  let envelopeQuery = getEnvelopeQuery(currentUser.accountId);

  const [envelopes] = useCollectionData(envelopeQuery);

  const [filterList, setFiltersList] = useState([]);



  const filterArray = () => {
    if (envelopes) {
      console.log(isLeft)
      let filterData = [];
      if (isLeft) {
        // console.log(envelope.senderId , envelope.senderId !== currentUser.accountId )

        filterData = envelopes.filter(envelope => !(envelope.senderId === currentUser.accountId));
        filterData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      } else {
        console.log("send")
        filterData = envelopes.filter(envelope => envelope.senderId === currentUser.accountId);
        filterData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      }


      setFiltersList(filterData);
    }
  }
  useEffect(() => {

    filterArray();



  }, [envelopes]);







  useInterval(() => {
    // fetchTokenPrice();
    updateBalance();
  }, 50000);

  useEffect(() => {
    updateBalance();
  }, [])

  const getUnixTimestamp = () => {
    return moment().unix();
  }





  const handleSetLinkDropRewardInput = () => {
    let usdReward = document.getElementById("linkDropRewardId").value.trim();

    setLinkDropUSDReward(usdReward);

    // setIinkDropReward((usdReward / usdPrice).toFixed(2));
  };

  const acceptedToken = [
    { name: 'BLACKDRAGON', contract: 'blackdragon.tkn.near', multiplier: 1000000 },
    { name: 'NEKO', contract: 'ftv2.nekotoken.near', multiplier: 10000 },
    { name: 'LONK', contract: 'token.lonkingnearbackto2024.near', multiplier: 1000000 },
    { name: 'SHITZU', contract: 'token.0xshitzu.near', multiplier: 1000000 }
  ];

  const chooseTokenLogo = (requestTokenContract) => {

    console.log("chooseTokenLogo", requestTokenContract);
    let logoClass = ""

    if (requestTokenContract == "blackdragon.tkn.near") {
      logoClass = "blackdragon-logo"
    } else if (requestTokenContract == "ftv2.nekotoken.near") {
      logoClass = "neko-logo"
    } else if (requestTokenContract == "token.lonkingnearbackto2024.near") {
      logoClass = "lonk-logo"
    } else if (requestTokenContract == "token.0xshitzu.near") {
      logoClass = "shitzu-logo"
    }

    return logoClass;
  }

  const handleCreateEnvelop = async (data) => {
    // let rewardInNear = (data.linkDropReward / usdPrice).toFixed(2);
    let tokenContract = ""
    let multiplier = 0

    setIsLoading(true);

    if (tokenName == "BLACKDRAGON") {
      let zerosToAdd = 24; // Number of zeros you want to add
      // finalResult =  1000000000000000000000000;//Number('1' + '0'.repeat(zerosToAdd));
      // 100000000 * multipler ( 1 M)
      tokenContract = "blackdragon.tkn.near"
      multiplier = 1000000;

    } else if (tokenName == "NEKO") {
      let zerosToAdd = 24; // Number of zeros you want to add
      finalResult = Number('1' + '0'.repeat(zerosToAdd));
      tokenContract = "ftv2.nekotoken.near"
      // 100000000 * multipler ( 1 k)

      multiplier = 1;
    } else if (tokenName == "LONK") {
      //  100000 is 0.001
      //  100000000 is 1
      let zerosToAdd = 8; // Number of zeros you want to add
      finalResult = Number('1' + '0'.repeat(zerosToAdd));
      tokenContract = "token.lonkingnearbackto2024.near"
      // 100000000 * multipler ( 1 M)
      multiplier = 1000000;

    } else if (tokenName == "SHITZU") {
      tokenContract = "token.0xshitzu.near"
      multiplier = 1
    }
    let envelop = {
      'subject': data.subject,
      'token': {
        name: tokenName,
        // Contract ID of the fungible token
        contractId: tokenContract,
        // Who will be sending the FTs to the Keypom contract

        // How many FTs should be sent to the claimed account everytime a key is used
        amount: data.linkDropReward * multiplier,
      },
      senderId: currentUser.accountId,
    }

    let res = await wallet.createDrop(envelop);
    // console.log("this is final ...", res);

    if (res) {
      sendEnvelop(res)
      setEnvelopModel(false);
      reset();
      setLinkDropUSDReward("");
    }


  }

  const createNewLink = async () => {

    const chatRoomData = {
      id: "",
      username: "",
      emailId: "",
      verified: false,
      isSuper: false,
      createdBy: currentUser.accountId,
      createdAt: getUnixTimestamp(),
      updateAt: getUnixTimestamp(),
      // Add any other metadata about the chat room
    };
    // console.log("chatRoomData:",chatRoomData);
    const obj = await createChatRoom(chatRoomData);
    chatRoomData.id = obj?.id;

    return chatRoomData;
  };


  const sendEnvelop = async (envelop) => {
    const newId = uuidv4();
    const newIdString = newId.toString();
    const messageObj = {
      id: newIdString,
      content: 'Envelop',
      createdAt: getUnixTimestamp(),
      unread: true,
      senderId: currentUser.accountId,
      member: 2,
      participants: [
        currentUser.accountId,
      ],
      ...envelop,
      isAmtClaim: false,
    };

    const obj = await createEnvelope(messageObj);
    if (obj) {
      setIsLoading(false)
      setShareLink(BaseURL + messageObj.id)
      setShareEnvelopModel(true)
    } else {
      setIsLoading(false)
    }

    return messageObj;
  };

  const [isLeft, setIsLeft] = useState(true);

  const handleToggle = (left) => {
    setIsLeft(left);
  };


  useEffect(() => {
    filterArray();
  }, [isLeft]);

  if (isLoading) {
    return <>
      <div className="background">
        <div className="logoContainer">
          <div className="logo"></div>
        </div>
        <div className="desktopAccountButtonContainer">

        </div>
        <div class="loader-3 center"><span></span></div>
      </div>
    </>
  }


  return (
    // <div className="background" style={{ display: 'flex', height: "100svh", justifyContent: 'center' }}>
    //   <div style={{
    //     width: "20vw", background: 'rgba(255, 255, 255, 0.09)',
    //     borderRadius: "16px",
    //     boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    //     backdropFilter: blur("1.3px"),
    //     border: '1px solid rgba(255, 255, 255, 0.3)',
    //     margin: '5px',
    //     padding: "20px"
    //   }}>
    //     <div className="logo-stamp"></div>
    //     <h3>{currentUser.accountId}</h3>



    //     <Button
    //       onClick={() => {
    //         wallet.signOut(); navigate("/");
    //       }}
    //       style={{
    //         backgroundColor: "#4FB3BF",
    //         color: "#FFFFFF",
    //         minWidth: 100,
    //         borderRadius: 50,
    //       }}
    //     >
    //       logout
    //     </Button>
    //     <Button
    //       onClick={() => {
    //         setEnvelopModel(true)
    //       }}
    //       style={{
    //         backgroundColor: "#4FB3BF",
    //         color: "#FFFFFF",
    //         minWidth: 100,
    //         borderRadius: 50,
    //       }}
    //     >
    //       Create New Envelop
    //     </Button>
    //     <Button
    //       onClick={() => {
    //         wallet.claimToken("5PM4fLcKdh4sGGZPSXGY6DMEVKjBc2wVKqqQHuUXrRDac2HAvJT8emQNsj91uAQA4B2tKWPzRMhHJE3U9DJyZTxP")
    //       }}
    //       style={{
    //         backgroundColor: "#4FB3BF",
    //         color: "#FFFFFF",
    //         minWidth: 100,
    //         borderRadius: 50,
    //       }}
    //     >
    //       Claim
    //     </Button>
    //   </div>
    //   <div style={{
    //     background: 'rgba(255, 255, 255, 0.09)',
    //     borderRadius: "16px",
    //     boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    //     backdropFilter: blur("1.3px"),
    //     border: '1px solid rgba(255, 255, 255, 0.3)',
    //     width: "70vw",
    //     margin: '5px',
    //     padding: "20px",
    //     display: 'flex',
    //     flexDirection: 'column',


    //   }}>

    //     <div style={{ display: 'flex', flexDirection: "row", }}>
    //       {envelopes && envelopes.map(item => (
    //         <div key={item.id} style={{ margin: '5px' }}>
    //           <div className="letter-image">

    //             <div className="animated-mail">

    //               <div className="back-fold"></div>
    //               <div className="letter">
    //                 <div className="logo-stamp"></div>
    //                 <div className="letter-context-body">
    //                   <p><strong>Dear {item.content.name},</strong><br /> {item.content.subject}</p>
    //                   <button>claim {item.content.token.amount}  {item.content.token.name} </button><br />
    //                 </div>
    //                 <div className="letter-stamp">
    //                   <div className="letter-stamp-inner"></div>
    //                 </div>
    //               </div>
    //               <div className="top-fold"></div>
    //               <div className="body"></div>
    //               <div className="left-fold">
    //                 <h6>From {item.senderId}</h6>
    //               </div>
    //             </div>
    //             <div className="shadow"></div>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //     {/* examples start */}
    //     <div style={{ display: 'flex', flexDirection: "row" }}>
    //       <h5>Example</h5>
    //       <div style={{ margin: '5px' }}>
    //         <div class="letter-image">

    //           <div class="animated-mail">

    //             <div class="back-fold">

    //             </div>
    //             <div class="letter">
    //               <div className="logo-stamp"></div>
    //               {/* <div class="letter-border">

    //               </div> */}
    //               {/* <div class="letter-title"></div>
    //               <div class="letter-context"></div> */}
    //               <div class="letter-context-body">
    //                 <p><strong>Dear User 1,</strong><br /> you are invited Dragon Mail 2024</p>
    //                 <button>claim</button><br />


    //               </div>
    //               <div class="letter-stamp">
    //                 <div class="letter-stamp-inner">

    //                 </div>
    //               </div>
    //             </div>
    //             <div class="top-fold">

    //             </div>
    //             <div class="body">

    //             </div>

    //             <div class="left-fold">
    //               <h6>From 0x4227.testnet</h6>


    //             </div>
    //           </div>
    //           <div class="shadow"></div>
    //         </div>
    //       </div>
    //       <div style={{ margin: '5px' }}>
    //         <div class="letter-image">
    //           <div class="animated-mail">
    //             <div class="back-fold"></div>
    //             <div class="letter">
    //               <div class="letter-border"></div>
    //               <div class="letter-title"></div>
    //               <div class="letter-context"></div>
    //               <div class="letter-context-body">
    //                 <p><strong>Dear User 3,</strong><br /> you are invited Dragon Mail 2024</p>
    //                 <button>claim</button><br />
    //                 <p>From 0x4227.testnet</p>
    //               </div>
    //               <div class="letter-stamp">
    //                 <div class="letter-stamp-inner">

    //                 </div>
    //               </div>
    //             </div>
    //             <div class="top-fold">

    //             </div>
    //             <div class="body">

    //             </div>
    //             <div class="left-fold"></div>
    //           </div>
    //           <div class="shadow"></div>
    //         </div>
    //       </div>
    //       <div style={{ margin: '5px' }}>
    //         <div class="letter-image">
    //           <div class="animated-mail">
    //             <div class="back-fold"></div>
    //             <div class="letter">
    //               <div class="letter-border"></div>
    //               <div class="letter-title"></div>
    //               <div class="letter-context"></div>
    //               <div class="letter-context-body">
    //                 <p><strong>Dear User 4,</strong><br /> you are invited Dragon Mail 2024</p>
    //                 <button>claim</button><br />
    //                 <p>From 0x4227.testnet</p>

    //               </div>
    //               <div class="letter-stamp">
    //                 <div class="letter-stamp-inner">

    //                 </div>
    //               </div>
    //             </div>
    //             <div class="top-fold">

    //             </div>
    //             <div class="body">

    //             </div>
    //             <div class="left-fold"></div>
    //           </div>
    //           <div class="shadow"></div>
    //         </div>
    //       </div>
    //       <div style={{ margin: '5px' }}>
    //         <div class="letter-image">
    //           <div class="animated-mail">
    //             <div class="back-fold"></div>
    //             <div class="letter">
    //               <div class="letter-border"></div>
    //               <div class="letter-title"></div>
    //               <div class="letter-context"></div>
    //               <div class="letter-context-body">
    //                 <p><strong>Dear User 2,</strong><br /> you are invited Dragon Mail 2024</p>
    //                 <button>claim</button><br />
    //                 <p>From 0x4227.testnet</p>

    //               </div>
    //               <div class="letter-stamp">
    //                 <div class="letter-stamp-inner">

    //                 </div>
    //               </div>
    //             </div>
    //             <div class="top-fold">

    //             </div>
    //             <div class="body">

    //             </div>
    //             <div class="left-fold"></div>
    //           </div>
    //           <div class="shadow"></div>
    //         </div>
    //       </div>
    //     </div>

    //     {/* examples end */}
    //   </div>

    //   {/* Reaward Pop*/}
    // <Dialog
    //   PaperProps={{
    //     style: {
    //       borderRadius: 15,
    //       backgroundColor: "#005662",
    //       color: "#FFFFFF",
    //     },
    //   }}
    //   open={createEnvelopModel}
    //   // overlayStyle={{ backgroundColor: "transparent" }}
    //   style={{ backgroundColor: "transparent" }}
    // >
    //   <DialogTitle>
    //     <Typography align="center">Create Envelope</Typography>
    //   </DialogTitle>
    //   <DialogContent>
    //     <DialogContentText></DialogContentText>
    //     <div style={{ position: "relative" }}>
    //       <div className="reward-input-field">
    //         <form onSubmit={handleSubmit(handleCreateEnvelop)}>

    //           {/* <div className="inputIcon" style={{ width: "50px" }}></div> */}
    //           <input
    //             type="text"

    //             placeholder="Name"
    //             className="input-field"
    //             {...register("name", { required: true })}
    //             // onChange={handleSetLinkDropRewardInput}
    //             // placeholder="0.0"
    //             // value={linkDropUSDReward}
    //             id="name"
    //             required
    //             // autoFocus
    //             style={{ backgroundColor: "#005662", color: "white" }}
    //             autoComplete="off"
    //           />
    //           <input
    //             type="text"

    //             placeholder="Subject"
    //             className="input-field"
    //             {...register("subject", { required: true })}
    //             // onChange={handleSetLinkDropRewardInput}
    //             // placeholder="0.0"
    //             // value={linkDropUSDReward}
    //             id="subject"
    //             required
    //             // autoFocus
    //             style={{ backgroundColor: "#005662", color: "white" }}
    //             autoComplete="off"
    //           />

    //           <input
    //             type="number"
    //             min="0"
    //             placeholder="0.0 TKN"
    //             className="input-field"
    //             {...register("linkDropReward", { required: true })}
    //             onChange={handleSetLinkDropRewardInput}
    //             // placeholder="0.0"

    //             value={linkDropUSDReward}
    //             id="linkDropRewardId"

    //             style={{ backgroundColor: "#005662", color: "white" }}
    //             autoComplete="off"
    //           />


    //           <Typography align="center">
    //             Max{" "}
    //             {currentUser.balance != 0
    //               ? currentUser.balance
    //               : 0}{" "}
    //             TKN
    //           </Typography>

    //           {/* <input type="submit" /> */}
    //           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    //             <Button
    //               onClick={() => {
    //                 setEnvelopModel(false);
    //               }}
    //               style={{
    //                 backgroundColor: "#4FB3BF",
    //                 color: "#FFFFFF",
    //                 minWidth: 100,
    //                 borderRadius: 50,
    //               }}
    //             >
    //               Cancel
    //             </Button>
    //             <Button
    //               type="submit"
    //               style={{
    //                 backgroundColor: "#4FB3BF",
    //                 color: "#FFFFFF",
    //                 minWidth: 100,
    //                 borderRadius: 50,
    //               }}
    //             >
    //               Create
    //             </Button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </DialogContent>

    // </Dialog>
    // </div>

    //Updated version below 
    < div className="background">

      <div className="envelope-logo-container">
        <div className="envelope-logo"></div>
      </div>
      <div className="desktopAccountButtonContainer">
        <div
          style={{
            height: 40,
          }}
          className="account-btn-grad"
          onClick={() => {
            wallet.signOut();
            navigate("/");
          }}
        >
          {/* <div style={{ fontsize: "24px;", color: "#FFFFFF" }}>account.near</div> */}
          <div style={{ fontsize: "24px;", color: "#FFFFFF" }}>Logout</div>
        </div>

      </div>

      {/* Account balance dropdown*/}
      {/* <div
        style={{
          height: 40,
          width: 120,
          fontsize: "24px;",
          color: "#FFFFFF",
        }}
        className="token-btn-grad"
        onClick={() => {
        }}
      >
        {currentUser.balance} TKN
      </div> */}

      <div className="account-balance-dropdown-container">
        <div
          className="dot-border"
          style={{ position: 'absolute', display: isNotif ? 'block' : 'none', width: '28px', height: '28px', marginLeft: '0px', left: '-8px', top: '-8px' }}
        >

        </div>
        <button
          onClick={() => {
            setOpenAccountDropdownButton(!openAccountDropdownButton)
          }}
          className={`account-balance-dropdown ${openAccountDropdownButton ? 'show-balance' : ''}`}
          style={{
            backgroundColor: openAccountDropdownButton ? '#ffffff' : '#E7756E',
            color: openAccountDropdownButton ? '#D96F34' : '#ffffff',
          }}
        >
          Balance
        </button>

        <div
          className={`account-balance-dropdown-content ${openAccountDropdownButton ? 'show-balance' : ''}`}
        >
          <a
            style={{
              display: "flex",
              color: "white",
              paddingLeft: "5px",
              flexDirection: "row",
              cursor: "pointer",
              border: isNotifBlackdragon ? '4px solid #D1A251' : 'transparent',
              borderRadius: isNotifBlackdragon ? '3' : '',
              borderBottom: setBorderBottom(isNotifBlackdragon, isNotifNeko),
            }}>
            <div className="blackdragon-logo" style={{ marginRight: "10px" }}></div>
            <div className="H14">
              {currentUser.bkBalance} BLACKDRAGON
            </div>
          </a>
          <a
            style={{
              display: "flex",
              color: "white",
              paddingLeft: "5px",
              flexDirection: "row",
              cursor: "pointer",
              border: isNotifNeko ? '4px solid #D1A251' : 'transparent',
              borderRadius: isNotifNeko ? '3' : '',
              borderBottom: setBorderBottom(isNotifNeko, isNotifLonk),
            }}>
            <div className="neko-logo" style={{ marginRight: "10px" }}></div>
            <div className="H14">
              {currentUser.nkBalance} NEKO
            </div>
          </a>
          <a
            style={{
              display: "flex",
              color: "white",
              paddingLeft: "5px",
              flexDirection: "row",
              cursor: "pointer",
              border: isNotifLonk ? '4px solid #D1A251' : 'transparent',
              borderRadius: isNotifLonk ? '3' : '',
              borderBottom: setBorderBottom(isNotifLonk, isNotifShitzu),
            }}>
            <div className="lonk-logo" style={{ marginRight: "10px" }}></div>
            <div className="H14">
              {currentUser.lonkBalance} LONK
            </div>
          </a>
                 
          <a
            style={{
              display: "flex",
              color: "white",
              paddingLeft: "5px",
              flexDirection: "row",
              cursor: "pointer",
              border: isNotifShitzu ? '4px solid #D1A251' : 'transparent',
              borderRadius: isNotifShitzu ? '3' : '',
            }}>
            <div className="shitzu-logo" style={{ marginRight: "10px" }}></div>
            <div className="H14">
              {currentUser.shBalance} SHITZU
            </div>
          </a> 

          <a
            style={{
              display: "flex",
              color: "white",
              paddingLeft: "5px",
              flexDirection: "row",
              cursor: "pointer",
              height: '20px',
            }}
          >
            <div className="stake-button">
              <div className="H14" style={{ fontSize: '16px' }}
                onClick={() => {
                  // window.location.href = ''
                  window.open(
                    'https://app.ref.finance/meme',
                    '_blank' // <- This is what makes it open in a new window.
                  );
                  setIsNotif(false)
                  setIsNotifBlackdragon(false)
                  setIsNotifNeko(false)
                  setIsNotifLonk(false)
                  setIsNotifShitzu(false)
                }}
              >
                Stake Now
              </div>
            </div>
          </a>

        </div>
      </div>

      {/* End of account balance dropdown */}

      <div className="buttonBox">
        <div id="btn" style={{ left: isLeft ? '0' : '140px' }}></div>
        <button type="button" className="toggleBtn" onClick={() => handleToggle(true)}>
          Received
        </button>
        <button type="button" className="toggleBtn" onClick={() => handleToggle(false)}>
          Sent
        </button>
      </div>

      <div className="envelopeBackgroundContainer">
        {filterList && filterList.map(item => (
          <div key={item.id} className="envelopeContainer" style={{ border: `6px solid ${item.isAmtClaim ? 'transparent' : '#D1A251'}`, borderRadius: 3 }}>
            
            <div className="envelope" 
              onClick={() => {
                setSelectedCard(item)
              }}
            >
            </div>
            
            <div>
              <div className="dot-border" onClick={() => { setSelectedCard(item) }} style={{ display: `${item.isAmtClaim ? 'none' : 'block'}` }} >
                <div className="H10" style={{ paddingLeft: '10px', paddingTop: '8px' }}>
                  Claim
                  <div
                    className={chooseTokenLogo(item.token.contractId)}
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      top: '1px',
                      marginLeft: '35px'
                    }}
                  >
                  </div>
                </div>
              </div>
              <div className="top-fold"></div>
              <div className="letter-top">
                <div style={{ position: 'absolute', display: 'flex', top: '10px', marginLeft: '5px' }}>
                  <div className="H11"> {item.content.subject}</div>
                </div>
              </div>
              <div className="letter-bottom">
                <div style={{ position: 'absolute', display: 'flex', top: '15px', marginLeft: '24px' }}>
                  <button onClick={() => {
                    // console.log(item.token.keyPair.secretKey)
                    wallet.claimToken(item.token.keyPair.secretKey)
                    chooseTokenNotif(item.token.contractId) // HARDCODE still need to change
                    setIsNotif(true)
                  }}>claim {item.token.amount}  {item.token.name} </button><br />
                </div>
                {/* <div style={{ position: 'absolute', display: 'flex', top: '15px', marginLeft: '24px' }}>
                <button onClick={() => { setShareEnvelopModel(true) }}>Share</button><br />
              </div> */}
              </div>
              <div className="senderName">
                <div style={{ overflow: 'clip', margin: '5px', }}>

                  {item.senderId === currentUser.accountId ? <div className="H12" style={{ cursor: 'pointer', paddingTop: "2px" }} onClick={() => {

                    setShareLink(BaseURL + item.id)
                    setShareEnvelopModel(true)
                  }}>
                    Share
                  </div> : <div className="H12" style={{ paddingTop: '2px' }}> {item.senderId}   </div>}
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* example below */}
        {/* <div className="envelopeContainer" style={{ border: `6px solid #D1A251`, borderRadius: 3 }}>
            
          <div className="envelope" 
            onClick={() => {
              setSelectedCard(item)
            }}
          > 
          </div>
          <div>
            <div className="dot-border" style={{ display: `block` }} >
              <div className="H10" style={{ paddingLeft: '10px', paddingTop: '8px' }}>
                Claim
                <div className="shitzu-logo"
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    top: '1px',
                    marginLeft: '35px'
                  }}
                >
                </div>
              </div>
            </div>
            <div className="top-fold"></div>
            <div className="letter-top">
              <div style={{ position: 'absolute', display: 'flex', top: '10px', marginLeft: '5px' }}>
                <div className="H11"> haha</div>
              </div>
            </div>
            <div className="letter-bottom">
              <div style={{ position: 'absolute', display: 'flex', top: '15px', marginLeft: '24px' }}>
                <button >claim 1000 shitzu </button><br />
              </div>
            </div>
            <div className="senderName">
              <div style={{ overflow: 'clip', margin: '5px', }}>

                airialtest.near <div className="H12" style={{ cursor: 'pointer', paddingTop: "2px" }} onClick={() => {

                  
                  setShareEnvelopModel(true)
                }}>
                  Share
                </div> : <div className="H12" style={{ paddingTop: '2px' }}> lalala.near  </div>}
              </div>
            </div>
          </div>
        </div> */}

      </div>

      <div className="desktopSpreadButtonContainer">
        <div
          style={{
            height: 40,
          }}
          className="dragonmail-glass-btn-grad"
          onClick={() => {
            setEnvelopModel(true)
            setOpenEnvelopModel(true)
          }}
        >
          Spread Prosperity!
        </div>
      </div>

      {/* Reward Pop*/}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            color: "#FFFFFF",
            width: "200px",
            height: "400px",
            borderRadius: 0,
            padding: 0,
            top: "-17px",
            justifyContent: "center",
            overflow: "visible",
            boxShadow: 'none',
            zIndex: 990,
          },
        }}
        open={createEnvelopModel}
        onBackdropClick={() => {
          setEnvelopModel(false);
        }}
        // overlayStyle={{ backgroundColor: "transparent" }}
        style={{ backgroundColor: "blur" }}
      >
        <div className="big-envelope-open" style={{ top: "62.5%" }}></div>
        <div className="big-envelope-fold" style={{ top: "25%" }}></div>
        <div style={{
          width: '180px',
          height: '260px',
          zIndex: 1005,
          backgroundColor: "#D9D9D9",
          marginLeft: "10px",
          marginTop: "25px",
          overflow: "visible",
        }}
        >
          <form onSubmit={handleSubmit(handleCreateEnvelop)}>
            <DialogContent
              style={{
                padding: "6px",
              }}
            >
              <div style={{ position: "relative", overflow: "visible" }}>
                <div className="reward-input-field" >


                  {/* <input
                    type="text"

                    placeholder="Name"
                    className="input-field"
                    {...register("name", { required: true })}
                    // onChange={handleSetLinkDropRewardInput}
                    // placeholder="0.0"
                    // value={linkDropUSDReward}
                    id="name"
                    required
                    // autoFocus
                    style={{ backgroundColor: "#005662", color: "white" }}
                    autoComplete="off"
                  /> */}
                  <textarea
                    type="text"

                    placeholder="Add a message"
                    className="input-field"
                    {...register("subject", { required: true })}

                    id="subject"
                    // autoFocus
                    style={{ backgroundColor: "#FFFFFF", color: "#D96F34", textAlign: "left", minHeight: "120px", maxWidth: "148px", fontSize: "12px", borderRadius: "8px", padding: "4px", verticalAlign: "text-top" }}
                    autoComplete="off"
                  />

                  <div className="dropdownContainer">
                  </div>

                  {/* <input
                    type="number"
                    min="0"
                    placeholder="Number of Links"
                    className="input-field"
                    {...register("numberOfLink", { required: true })}
                    onChange={handleSetLinkDropRewardInput}
                    // placeholder="0.0"
                    id="numberOfLink"
                    style={{ backgroundColor: "#005662", color: "white" }}
                    autoComplete="off"
                  /> */}

                  <input
                    type="number"

                    placeholder={tokenAmount}
                    aria-placeholder="max 30 tkn"
                    className="token-input-field"
                    {...register("linkDropReward", { required: true })}
                    onChange={handleSetLinkDropRewardInput}
                    // placeholder="0.0"

                    value={linkDropUSDReward}
                    id="linkDropRewardId"

                    style={{ backgroundColor: "#FFFFFF", color: "#D96F34", maxWidth: "148px", textAlign: "right", fontSize: "12px", borderRadius: "8px" }}
                    autoComplete="off"
                  />


                  {/* <Typography align="center">
                    Max{" "}
                    {currentUser.balance != 0
                      ? currentUser.balance
                      : 0}{" "}
                    TKN
                  </Typography> */}
                </div>
              </div>
            </DialogContent>

            <div className="dropdown">
              <button
                onClick={() => {
                  setOpenDropdownButton(!openDropdownButton)
                }}
                className={`dropbtn ${openDropdownButton ? 'show' : ''}`}
                style={{
                  backgroundColor: openDropdownButton ? '#ffffff' : '#E7756E',
                  color: openDropdownButton ? '#D96F34' : '#ffffff',
                }}
              >
                {tokenName}
              </button>

              <div
                className={`dropdown-content ${openDropdownButton ? 'show' : ''}`}
              >
                <a
                  onClick={() => {
                    setTokenName("BLACKDRAGON")
                    setTokenAmount("OM BLACKDRAGON")
                    setOpenDropdownButton(!openDropdownButton)
                  }}

                  style={{
                    display: "flex",
                    color: "white",
                    paddingLeft: "5px",
                    borderBottom: "3px solid",
                    borderColor: "#A30010",
                    flexDirection: "row",
                    cursor: "pointer",
                  }}>
                  <div className="H14">
                    BLACKDRAGON
                  </div>
                  <div className="blackdragon-logo" style={{ left: "20px" }}></div>
                </a>
                <a
                  onClick={() => {
                    setTokenName("NEKO")
                    setTokenAmount("OK NEKO")
                    setOpenDropdownButton(!openDropdownButton)
                  }}

                  style={{
                    display: "flex",
                    color: "white",
                    paddingLeft: "5px",
                    borderBottom: "3px solid",
                    borderColor: "#A30010",
                    flexDirection: "row",
                    cursor: "pointer",
                  }}>
                  <div className="H14">
                    NEKO
                  </div>
                  <div className="neko-logo" style={{ left: "77px" }}></div>
                </a>
                <a
                  onClick={() => {
                    setTokenName("LONK")
                    setTokenAmount("OM LONK")
                    setOpenDropdownButton(!openDropdownButton)
                  }}

                  style={{
                    display: "flex",
                    color: "white",
                    paddingLeft: "5px",
                    flexDirection: "row",
                    borderBottom: "3px solid",
                    borderColor: "#A30010",
                    cursor: "pointer",
                  }}>
                  <div className="H14">
                    LONK
                  </div>
                  <div className="lonk-logo" style={{ left: "77px" }}></div>
                </a>
                 <a
                  onClick={() => {
                    setTokenName("SHITZU")
                    setTokenAmount("O SHITZU")
                    setOpenDropdownButton(!openDropdownButton)
                  }}

                  style={{
                    display: "flex",
                    color: "white",
                    paddingLeft: "5px",
                    flexDirection: "row",
                    cursor: "pointer",
                  }}>
                  <div className="H14">
                    SHITZU
                  </div>
                  <div className="shitzu-logo" style={{ left: "67px" }}></div>
                </a> 
              </div>
            </div>
            <div className="triangle-down"></div>
            <div className="big-sender-name" style={{ marginTop: "96px", }}>
              {/* <Button
                onClick={() => {
                  setEnvelopModel(false);
                }}
                style={{
                  backgroundColor: "#4FB3BF",
                  color: "#FFFFFF",
                  minWidth: 50,
                  borderRadius: 35,
                }}
              >
                Cancel
              </Button> */}
              <Button
                type="submit"
                style={{
                  color: "#DBA95C",
                  borderRadius: 50,
                  width: "200px",
                  height: "50px",
                }}
              >
                Gift Prosperity
              </Button>
            </div>
          </form>
        </div>

      </Dialog>



      {/* Share Link Pop*/}
      <Dialog
        open={shareEnvelopModel}
        onBackdropClick={() => {
          setShareEnvelopModel(false);
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            color: "#FFFFFF",
            width: "200px",
            height: "400px",
            borderRadius: 0,
            padding: 0,
            top: "-17px",
            justifyContent: "center",
            overflow: "visible",
            boxShadow: 'none',
            zIndex: 990,
          },
        }}
      >
        <div className="big-envelope-open" style={{ top: "62.5%" }}></div>
        <div className="big-envelope-fold" style={{ top: "25%" }}></div>
        <div style={{
          width: '180px',
          height: '260px',
          zIndex: 1005,
          backgroundColor: "#D9D9D9",
          marginLeft: "10px",
          marginTop: "25px",
        }}
        >
          <DialogTitle titleStyle={{ textAlign: "center" }} style={{ justifyContent: "center", padding: "8px", paddingTop: "30px" }}>
            <div className="H12" style={{
              fontSize: "14px",
            }}> Share your Luck Pocket</div>
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogContentText
            alignItems="center"
            justifyContent="center"
            style={{ overflow: "clip", marginTop: "50px" }}
          >
            <div
              style={{
                padding: 10,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <div className="dragon-pocket"></div>
              <div
                className="copy-icon-dragonmail"
                onClick={async () => {

                  navigator.clipboard.writeText(shareLink);

                  if (navigator.clipboard) {
                    await navigator.clipboard.writeText(shareLink);
                    setShareEnvelopModel(false)
                  } else {
                    alert(shareLink);
                    setShareEnvelopModel(false)
                  }
                }}
              />
              <div className="link-box"><div className="H13">Link</div></div>
              <div style={{ flexDirection: "column" }}>

                <div
                  style={{
                    padding: 10,
                    width: "250px",
                    wordWrap: "break-word",
                    color: "#ffffff",
                    fontSize: 12,
                  }}
                  onClick={async () => {
                    // navigator.clipboard.writeText(link)

                    if (navigator.clipboard) {
                      await navigator.clipboard.writeText(shareLink);
                      setShareEnvelopModel(false);
                    } else {
                      alert(shareLink);

                      setShareEnvelopModel(false);
                    }
                  }}
                >
                  {/* {shareLink} */}
                </div>
              </div>
            </div>
          </DialogContentText>
          <div className="big-sender-name" style={{ marginTop: "96px", }}>
            <div style={{ overflow: 'clip', margin: '5px', }}>
              <div className="H12" style={{ fontSize: '16px', paddingTop: '8px' }}> Gift Prosperity </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* TODO: Need To change this */}
      {selectedCard && <div>
        <div className="new-letter">
          {selectedCard.subject}
          <br />
          <br />
          {selectedCard.token.amount}
          <br />
          {selectedCard.token.name ?? ''}
          {/* {selectedCard.token.name && "TKN"}  */}
        </div>
        <div className="big-envelope-open"></div>
        <div className="big-envelope-open-half"></div>
        <div className="big-envelope-fold"></div>
        <div className="blur-background" onClick={() => { setSelectedCard(null) }}></div>

        {!selectedCard.isAmtClaim ? <div
          className="claim-btn-grad"
          onClick={async () => {

            claimToken()
            setIsNotif(true);

          }}
        > claim</div>

          : <div
            className="claim-btn-grad"
            onClick={() => {
            }}
          > 	&#10003; </div>}

        <div
          className="close-btn-grad"
          onClick={() => {
            setSelectedCard(null)
          }}
        >close</div>
      </div>}


      {selectedCard && <div>
        <div className="new-letter">
          {selectedCard.subject}
          <br />
          <br />
          {selectedCard.token.amount}
          <br />
          {selectedCard.token.name ?? ''}
          {/* {selectedCard.token.name && "TKN"}  */}
        </div>
        <div className="big-envelope-open"></div>
        <div className="big-envelope-open-half"></div>
        <div className="big-envelope-fold"></div>
        <div className="blur-background" onClick={() => { setSelectedCard(null) }}></div>
        <div className="big-sender-name">
          <div style={{ overflow: 'clip', margin: '5px', }}>
            <div className="H12" style={{ fontSize: '16px', paddingTop: '8px' }}> {selectedCard.senderId}   </div>
          </div>
        </div>
        {!selectedCard.isAmtClaim ? <div
          className="claim-btn-grad"
          onClick={async () => {

            claimToken()

          }}
        > {selectedCard.senderId == currentUser.accountId ? "withdraw " : "claim"}</div>

          : <div
            className="claim-btn-grad"
            onClick={() => {
            }}
          > 	&#10003;</div>}

        <div
          className="close-btn-grad"
          onClick={() => {
            setSelectedCard(null)
          }}
        >close</div>
      </div>}
    </div>
  );
}
export default EnvelopePage;
