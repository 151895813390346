import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as nearAPI from "near-api-js";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RegistorInterface } from "./api/near/near-interface";
import { Wallet } from "./api/wallet/near-wallet";
import { isNearWallet } from "./api/node-api";

import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";


// const LINK_CONTRACT_NAME = "icebreak-drop.testnet";
// const LINK_CONTRACT_NAME = "dev-1707041746959-84859040980008";
// const NETWORK = "testnet";
const DRAGON_CONTRACT = "v1.fortuna-draco.near"

const LINK_CONTRACT_NAME = DRAGON_CONTRACT;
const NETWORK = "mainnet";

/*
const contract = await new nearAPI.Contract(
  // User's accountId as a string
  account,
  // accountId of the contract we will be loading
  // NOTE: All contracts on NEAR are deployed to an account and
  // accounts can only have one contract deployed to them.
  nearConfig.contractName,
  {
    // View methods are read-only – they don't modify the state, but usually return some value
    viewMethods: ["get_users", "get_created_conversation_fee"],
    // Change methods can modify the state, but you don't receive the returned value when called
    changeMethods: [
      "createConversation",
      "replyConversation",
      "send",
      "claim",
      "create_account_and_claim"
    ],
    // Sender is the account ID to initialize transactions.
    // getAccountId() will return empty string if user is still unauthorized

  }
);
*/

const wallet  = new Wallet(NETWORK)
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App
      wallet={wallet}
    />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

