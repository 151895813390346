import React, { useState, useEffect } from "react";
import Modal from "react-modal";
// import './LandingPage.css'
import "./../common/icebreak.css";
import modalLogo from "../../assets/modalLogo.svg";
import MyNearIconUrl from "@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png";
import { useNavigate } from "react-router-dom";
import * as nearAPI from "near-api-js";
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from "usehooks-ts";


import { getEnvelopeDetail } from "./../../api/firebase/MessageFirebaseRef"

function LandingPage({ wallet }) {


  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [invitee, setInvitee] = useLocalStorage("invitee", null);

  const [isClaim, setIsClaim] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => {
    checkUser();
  }, [wallet.isSignedIn]);

 
  useEffect(() => {
    if (id) {
      getEnvelopeCheckT(id)
    }

  }, [id]);

  const getEnvelopeCheckT = async (id) => {
    let data = await getEnvelopeDetail(id);
    if (data.unread) {
      setInvitee(data);
    } else {
      setIsClaim(!data.unread);
    }

  }

  const checkUser = async () => {

    // if (wallet.isSignedIn) {
    //   navigate("/app");
    // }
  };



  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    if (width < 640) setIsMobileView(true);
    else setIsMobileView(false);

  };



  const newUser = async () => {
    window.location.assign("/createWallet");
  }
  const connectWallet = async () => {
    localStorage.setItem("typeOfLogin", "existingUser");
    wallet.signIn();
    navigate("/");
  };


  return (
    // <div className="background">
    //   <div>
    //     <div class="background-image1"></div>
    //   </div>
    //   <div style={{ padding: 20, marginLeft: 50 }}>
    //     <div className="logo"></div>
    //   </div>
    //   <div style={{ padding: 20, marginLeft: 50 }}>
    //     <div className="H1">Make your message heard.</div>
    //     <div className="H2">reward replying</div>
    //   </div>
    //   <div style={{ padding: 30, marginLeft: 50 }} className="H6">
    //     New to Dragon Mail?{" "}
    //     <a
    //       href="https://icebreak.gitbook.io/icebreak-manual/claiming-your-reward"
    //       target="_blank"
    //     >
    //       <b className="H7">learn more</b>{" "}
    //     </a>
    //   </div>
    //   <div>
    //     {/* <div style={{display:'flex', position:'absolute',bottom:50, left:'50%',right:'50%', alignItems:'center',flexDirection:isMobileView?'column':'row', justifyContent:'center'}}>
    //       <div
    //         style={{

    //           minWidth:120,
    //           maxWidth:120,
    //           height: 40,
    //           background:
    //             "linear-gradient(270deg, #ACD7DB 5.21%, #4FB3BF 49.48%, #00838F 100%)",
    //         }}
    //         className="btn-grad"
    //         onClick={() => connectWallet()}
    //       >
    //         Connect
    //       </div>
    //       <div
    //         style={{
    //           minWidth:120,
    //           maxWidth:120,
    //         //   minWidth:100,
    //           height: 40,
    //           background:
    //             "linear-gradient(270deg, #ACD7DB 5.21%, #4FB3BF 49.48%, #00838F 100%)",
    //         }}
    //         className="btn-grad"
    //         onClick={() => newUser()}
    //       >
    //         New User
    //       </div>
    //     </div> */}
    //     {/* Chinese version below */}
    //     <div style={{ display: 'flex', position: 'absolute', bottom: 50, left: '50%', right: '50%', alignItems: 'center', flexDirection: isMobileView ? 'column' : 'row', justifyContent: 'center' }}>
    //       <div
    //         style={{
    //           minWidth: 120,
    //           maxWidth: 120,
    //           height: 40,
    //         }}
    //         className="glass-btn-grad"
    //         onClick={() => connectWallet()}
    //       >
    //         Connect
    //       </div>
    //       <div
    //         style={{
    //           minWidth: 120,
    //           maxWidth: 120,
    //           height: 40,
    //         }}
    //         className="glass-btn-grad"
    //         onClick={() => newUser()}
    //       >
    //         New User
    //       </div>
    //     </div>
    //   </div>

    //   <div className="footer centered">
    //     <div className="build-on"></div>
    //   </div>

    //   {/* Model For Connecting */}
    //   <Modal
    //     className="model"
    //     isOpen={modalIsOpen}
    //     onRequestClose={() => setModalIsOpen(false)}
    //   >
    //     <div className="H6">Connect to Near Wallet</div>
    //     <img height={80} width={80} src={MyNearIconUrl} />

    //     <div className="H6">connect wallet to get started</div>
    //     <div className="button" onClick={connectWallet}>
    //       Connect
    //     </div>
    //   </Modal>
    // </div>

    // chinese version below
    <div className="background">
      <div className="backgroundImageContainer">
        <div class="background-image2"></div>
      </div>
      <div className="logoContainer">
        <div className="logo"></div>
      </div>
      <div className="textContainer">
        <div className="H1">Unlock Your Fortune!</div>
        {invitee && <div className="H7">From {invitee.senderId}</div>}
        {isClaim && <div className="H7">Already Claim</div>}
        <div className="H6">
          New to Dragon Mail?{" "}
          <a
            href="https://icebreak.gitbook.io/icebreak-manual/claiming-your-reward"
            target="_blank"
          >
            <b className="H7">learn more</b>{" "}
          </a>
        </div>
      </div>
      <div className="mobile-text-container">
        <div className="H5" style={{lineHeight: "24px"}}>Unlock Your Fortune!</div>
        {invitee && <div className="H7">From {invitee.senderId}</div>}
      </div>
      <div>
        {/* desktop button below */}
        <div className="desktopButtonContainer">
          <div
            style={{
              minWidth: 120,
              maxWidth: 120,
              height: 40,
            }}
            className="glass-btn-grad"
            onClick={() => { wallet.isSignedIn ? navigate('/app') : connectWallet() }}
          >
            {wallet.isSignedIn ? "Dashboard" : "Connect"}
          </div>
          {/* <div
            style={{
              minWidth: 120,
              maxWidth: 120,
              height: 40,
            }}
            className="glass-btn-grad"
            onClick={() => newUser()}
          >
            New User
          </div> */}
        </div>
        {/* mobile button below */}
        <div className="mobileButtonContainer">
          <div
            style={{
              minWidth: 120,
              maxWidth: 120,
              height: 40,
            }}
            className="glass-btn-grad"
            onClick={() => connectWallet()}
          >
            Connect
          </div>
          <div style={{ width: 150, }}>
            <div style={{ textAlign: 'center' }} className="H10">
              Already a user?{" "}
              <a
                onClick={() => newUser()}
              >
                <b className="H10">Sign in</b>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer centered">
        <div style={{ display: isMobileView ? 'none' : '' }} className="build-on"></div>
      </div>

      {/* Model For Connecting */}
      <Modal
        className="model"
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className="H6">Connect to Near Wallet</div>
        <img height={80} width={80} src={MyNearIconUrl} />

        <div className="H6">connect wallet to get started</div>
        <div className="button" onClick={connectWallet}>
          Connect
        </div>
      </Modal>
    </div>
  );
}
export default LandingPage;
