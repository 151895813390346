import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { FirebaseConfig, FirebaseName } from './config/firebaseConfig';

// Initialize Firebase

const FirebaseApp = firebase.initializeApp(FirebaseConfig, FirebaseName);
// const FirebaseAnalytics = getAnalytics(FirebaseApp);
const FirebaseAuth = firebase.auth(FirebaseApp);
const FireStore = firebase.firestore(FirebaseApp);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(getMessaging(FirebaseApp), (payload) => {
      resolve(payload);
    });
  });

export const Sendrequest = () => {
  console.log('Requesting User Permission......');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification User Permission Granted.');

      return getToken(getMessaging(FirebaseApp), {
        vapidKey: `BHqE9T4kmp7ZfdLZY5ywYatMRS7xZne-VNWpI5K3NXyUsyaKfg99hEZzow-09CkMy_155eTgmYzQdcPj5Hsex4Y`,
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log('Client Token: ', currentToken);
          } else {
            console.log('Failed to generate the registration token.');
          }
        })
        .catch((err) => {
          console.log('An error occurred when requesting to receive the token.', err);
        });
    } else {
      console.log('User Permission Denied.');
    }
  });
};

function initFirebase() {
  return true;
}

export {
  FirebaseAuth,
  FireStore,
  // FirebaseAnalytics,
  FirebaseApp,
  firebase,
  initFirebase,
  
};
