import './Pages/common/icebreak.css'
import DepositFundsPage from './Pages/DepositFundsPage/DepositFundsPage';

import MainPage from './Pages/MainPage/MainPage';
import Demo from './Pages/MainPage/Demo';
import SharePage from './Pages/SharePage/SharePage';
import SignupPage from './Pages/SignupPage/SignupPageO';
import { BrowserRouter as Router, Route, Routes, Switch, Redirect } from 'react-router-dom';
import Modal from 'react-modal'
import { useState, useEffect } from 'react';
import React from 'react';
import { useInterval } from 'ahooks';



import LandingPage from './Pages/LandingPage/LandingPage';
import EnvelopePage from './Pages/Envelope/EnvelopePage';
Modal.setAppElement("#root")
function App({ wallet }) {

  const [isLogin, setIsLogin] = useState(false)

  useInterval(() => {

    checkLogin()
  }, 1000);

  const checkLogin = async () => {
    let res = await wallet.checkSignIn();
    setIsLogin(res);
  }

  useEffect(() => {
    checkLogin();
  }, [wallet])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage wallet={wallet} />} />
        <Route path="/app" element={<EnvelopePage wallet={wallet} />} />
        <Route path="/share" element={<SharePage wallet={wallet} />} />
      </Routes>
    </Router>
  );

  /*


  function loginRedirect(currentUser) {
   
    if (currentUser != undefined) {
     if (currentUser.profile != undefined) {
        return <Redirect to="/mainpage" />
      } else {
        return <Redirect to="/signup" />
      }
    }
  }



  function checkTheShareCode(currentUser) {
  
  return <Redirect to="/recipientlanding" />
  }

  function loadMainPage(){
    if (currentUser != undefined) {
      
      if (currentUser.profile != undefined) {
         return <Redirect to="/mainpage" />
       }else{
        console.log('rediret signup')
        return;
       }
     }
  }


  function invalidLogin(currentUser) {

    if (currentUser == undefined) {
      return <Redirect to="/" />
    }
  }

  return (
    <Router>
      <div className="background">
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return (
                currentUser == undefined ?
                  <Redirect to="/landingPage" />
                  : currentUser.profile != undefined ?
                    <Redirect to="/mainpage" /> :
                    <Redirect to="/signup" />
              )
            }}
          >

          </Route>
          <Route path="/landingPage" exact component={() => <LandingPage walletConnection={walletConnection} currentUser={currentUser} nearConfig={nearConfig} contract={contract} />} >
          {
              // checkTheShareCode(currentUser)
            }
          </Route>
          <Route path="/signup" exact component={() => <SignupPage walletConnection={walletConnection} currentUser={currentUser} contract={contract}  createWallet={false}  nearConfig={nearConfig}/>} >
          {   console.log('signup')}
            {
             // loadMainPage(currentUser)
            }
          </Route>

          <Route path="/createWallet" exact component={() => <SignupPage walletConnection={walletConnection} currentUser={currentUser} contract={contract}  createWallet={true} nearConfig={nearConfig}/>} >
          {   console.log('createWallet')}
            {
             // loadMainPage(currentUser)
            }
          </Route>


          
          <Route path="/depositfunds" exact component={DepositFundsPage} />
          <Route path="/recipientlanding" exact component={() => <RecipientLandingPage walletConnection={walletConnection} currentUser={currentUser} nearConfig={nearConfig} contract={contract} />} >

            
          </Route>
          <Route path="/mainpage" exact component={() => <MainPage account ={account} walletConnection={walletConnection} currentUser={currentUser} contract={contract} nearConfig={nearConfig} />} >
          {
              invalidLogin(currentUser)
            }
          </Route>
          <Route path="/demo" exact component={() => <Demo walletConnection={walletConnection} currentUser={currentUser} contract={contract} nearConfig={nearConfig} />} >
          {
             
          }
          </Route>
        </Switch>
      </div>
    </Router>
  );

  */
}



export default App;
