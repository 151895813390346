import React, { useState, useEffect } from "react";
// import './SignupPage.css'
import "./../common/icebreak.css";
import Modal from "react-modal";
import exclamatoryLogo from "../../assets/exclamatory.svg";
import { createUser, sendEmail } from "../../api/node-api";
import { isNearWallet } from "../../api/node-api";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Grid, Row, Col } from "react-flexbox-grid";
import { generateSeedPhrase, parseSeedPhrase } from "near-seed-phrase";
import * as nearAPI from "near-api-js";

import Container from "react-bootstrap/Container";

function SignupPage({
  walletConnection,
  currentUser,
  createWallet,
  nearConfig,
  contract,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [emailId, setEmailId] = useState("");

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    // let profile = await isNearWallet({nearAccount:walletConnection.getAccountId()});
    if (currentUser != undefined && currentUser.profile != undefined) {
      window.location.assign("/");
    }
  };

  const openSnackbardModel = async (msg, severity) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(msg);
    setSnackbarOpen(true);
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailId = (e) => {
    setEmailId(e.target.value);
  };

  const handleSignOut = () => {};

  const handleKeyDown = (e) => {
    var currentMessages = {};

    if (e.key === "Enter") {
      handleSignup();
    }
  };

  async function seedPhraseToWalletAddress(publicKey) {
    var address = nearAPI.utils.PublicKey.fromString(publicKey).data;
    return bufferToHex(address);
  }

  function bufferToHex(buffer) {
    return [...new Uint8Array(buffer)]
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  }

  const handleCreateWallet = async () => {
    var letters = /^[0-9a-zA-Z-@]+$/;

    if (username.match(letters)) {
      const { seedPhrase, secretKey, publicKey } = generateSeedPhrase();
      let wallletAddress = await seedPhraseToWalletAddress(publicKey);
      let url =
        nearConfig.walletUrl +
        "/recover-with-link/" +
        wallletAddress +
        "/" +
        encodeURIComponent(seedPhrase);

      // JSON.stringify({
      //   seedPhrase: seedPhrase,
      //   secretKey: secretKey,
      //   publicKey: publicKey,
      //   url: url,
      //   wallletAddress: wallletAddress,
      // })

//       const htmlEmailTemplate =
//         `
// <!DOCTYPE html>
// <html lang='en'>
// <head>
//     <meta charset='UTF-8'>
//     <title>Important Information - Saving Your Seed Phrase</title>
// </head>
// <body>
//     <p>Dear New User,</p>
//     <p>We hope you are enjoying using Icebreak as your preferred cryptocurrency platform. We are writing this email to inform you that we have generated a new Seed Phrase and Wallet Address for your account. Please make sure to keep your Seed Phrase and Wallet Address safe and secure, as they are the only way to access your account and your funds.</p>
//     <p>Your Seed Phrase is:</p>
//     <p style="font-size: 1.2rem; font-weight: bold;"> ` +
//         seedPhrase +
//         `</p>
//     <p>Your Wallet Address is:</p>
//     <p style="font-size: 1.2rem; font-weight: bold;"> ` +
//         wallletAddress +
//         `</p>
//     <p>You can use URL to Login into your walllet:</p>
//     <p style="font-size: 1.2rem; font-weight: bold;"> ` +
//         url +
//         `</p>
//     <p>Please note that Icebreak does not save your Seed Phrase or Wallet Address on our servers. It is your responsibility to keep them safe and secure.</p>
//     <p>To ensure the security of your cryptocurrency wallet, we recommend that you follow these guidelines:</p>
//     <ol>
//         <li>Write down your Seed Phrase and Wallet Address on a piece of paper and store them in a safe place.</li>
//         <li>You can also store your Seed Phrase and Wallet Address using a hardware wallet, such as [example of hardware wallet name].</li>
//         <li>Do not share your Seed Phrase or Wallet Address with anyone, including our customer support team.</li>
//         <li>Do not save your Seed Phrase or Wallet Address on your computer or any digital device that can be hacked or stolen.</li>
//         <li>Make sure to keep a backup copy of your Seed Phrase and Wallet Address in a separate location.</li>
//         <li>Remember, the security of your account also depends on your own security measures. Please make sure to use strong passwords, enable two-factor authentication, and keep your devices secure to prevent unauthorized access to your account.</li>
//     </ol>
//     <p>We understand that safeguarding your Seed Phrase and Wallet Address can be challenging, but it is vital for the security of your funds. Please take the time to follow these guidelines and secure your Seed Phrase and Wallet Address to avoid any future complications.</p>
//     <p>If you have any questions or concerns, please feel free to contact our customer support team. We are always here to assist you.</p>
//     <p>Thank you for choosing Icebreak. We look forward to serving you.</p>
//     <p>Best regards,</p>
//     <p>Icebreak</p>
// </body>
// </html>`;
const htmlEmailTemplate =
`
<!DOCTYPE html>
<html>
<head>
	<title>Icebreak - Retrieve Your Reward</title>
</head>
<body>
	<p>Dear New User,</p>
	<p>Welcome to Icebreak, the platform that rewards replying. In case you are signing up after receiving a reply reward, you may use the following information below to take custody of it, by setting up your very own NEAR wallet:</p>
	<p><a href="`+
  url +
  `">` +
  url +
  `</a></p>
	<p>Please note that Icebreak does not save your Seed Phrase or Wallet Address on our servers. It is your responsibility to keep them safe and secure. In case you would like to manually retrieve the funds, you can do so with the following information:</p>
	<p>Seed Phrase:</p>
	<blockquote>` +
  seedPhrase +
  `</blockquote>
	<p>Wallet Address:</p>
	<blockquote>` +
  wallletAddress +
  `</blockquote>
	<p>To ensure the security of your cryptocurrency wallet, we recommend that you follow these guidelines:</p>
	<ol>
		<li>Write down your Seed Phrase and Wallet Address on a piece of paper and store them in a safe place.</li>
		<li>You can also store your Seed Phrase and Wallet Address using a hardware wallet, such as [example of hardware wallet name].</li>
		<li>Do not share your Seed Phrase or Wallet Address with anyone, including our customer support team.</li>
		<li>Do not save your Seed Phrase or Wallet Address on your computer or any digital device that can be hacked or stolen.</li>
		<li>Make sure to keep a backup copy of your Seed Phrase and Wallet Address in a separate location.</li>
		<li>Remember, the security of your account also depends on your own security measures. Please make sure to use strong passwords, enable two-factor authentication, and keep your devices secure to prevent unauthorized access to your account.</li>
	</ol>
	<p>We understand that safeguarding your Seed Phrase and Wallet Address can be challenging, but it is vital for the security of your funds. Please take the time to follow these guidelines and secure your Seed Phrase and Wallet Address to avoid any future complications.</p>
	<p>Best regards,</p>
	<p>Icebreak</p>
</body>
</html>`;


      let res2 = await createUser({
        userName: username,
        emailId: emailId,
        nearAccount: wallletAddress,
      });

      console.log(res2);

      if (!res2.status) {
        openSnackbardModel(res2.msg, "error");
      } else {
        let res = await sendEmail(emailId, username, htmlEmailTemplate);

        openSnackbardModel("registration success", "success");
        // setUsername("");
        localStorage.setItem("typeOfLogin", "newUser");
        localStorage.setItem("privateKey", secretKey);

        window.location.assign("/");
      }
    } else {
      setModalIsOpen(true);
    }
  };

  const handleSignup = async () => {
    var letters = /^[0-9a-zA-Z-@]+$/;
    if (username.match(letters)) {
      const htmlEmailTemplate =
        `
<!DOCTYPE html>
<html lang='en'>
<head>
    <meta charset='UTF-8'>
    <title>Important Information - Saving Your Seed Phrase</title>
</head>
<body>
    <p>Dear New User,</p>
    <p>We hope you are enjoying using Icebreak as your preferred cryptocurrency platform. We are writing this email to inform you that we have generated a new Seed Phrase and Wallet Address for your account. Please make sure to keep your Seed Phrase and Wallet Address safe and secure, as they are the only way to access your account and your funds.</p>
    <p>Your Seed Phrase is:</p>
    <p>Your Wallet Address is:</p>
    <p style="font-size: 1.2rem; font-weight: bold;"> ` +
        currentUser.accountId +`</p>
   
    <p>Please note that Icebreak does not save your Seed Phrase or Wallet Address on our servers. It is your responsibility to keep them safe and secure.</p>
    <p>To ensure the security of your cryptocurrency wallet, we recommend that you follow these guidelines:</p>
    <ol>
        <li>Write down your Seed Phrase and Wallet Address on a piece of paper and store them in a safe place.</li>
        <li>You can also store your Seed Phrase and Wallet Address using a hardware wallet, such as [example of hardware wallet name].</li>
        <li>Do not share your Seed Phrase or Wallet Address with anyone, including our customer support team.</li>
        <li>Do not save your Seed Phrase or Wallet Address on your computer or any digital device that can be hacked or stolen.</li>
        <li>Make sure to keep a backup copy of your Seed Phrase and Wallet Address in a separate location.</li>
        <li>Remember, the security of your account also depends on your own security measures. Please make sure to use strong passwords, enable two-factor authentication, and keep your devices secure to prevent unauthorized access to your account.</li>
    </ol>
    <p>We understand that safeguarding your Seed Phrase and Wallet Address can be challenging, but it is vital for the security of your funds. Please take the time to follow these guidelines and secure your Seed Phrase and Wallet Address to avoid any future complications.</p>
    <p>If you have any questions or concerns, please feel free to contact our customer support team. We are always here to assist you.</p>
    <p>Thank you for choosing Icebreak. We look forward to serving you.</p>
    <p>Best regards,</p>
    <p>Icebreak</p>
</body>
</html>`;

      let res = await createUser({
        userName: username,
        emailId: emailId,
        nearAccount: currentUser.accountId,
      });

      if (!res.status) {
        openSnackbardModel(res.msg, "error");
      } else {
        let res2 = await sendEmail(emailId, username, htmlEmailTemplate);
        openSnackbardModel("registration success", "success");
        setUsername("");
        window.location.assign("/");
      }
    } else {
      setModalIsOpen(true);
    }
  };
  return (
    <div className="background" style={{  height: '100svh'}}>
      <div>
          <div class="background-image1"></div>
      </div>
      <div style={{ padding: 30, display: "flex", flexDirection: "row" }}>
        <div className="logo" />
      </div>
      {/* <div className="H6">
                    {currentUser.accountId}
                </div> */}
      <div
        className="centered"
        style={{ display: "flex", flexDirection: "column" }}
      >
        
          <div
            style={{
              width: 270,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="H4">
              <span>Email Id :</span>
            </div>

            <div>
              <div className="chat-box-footer-input signup-input">
                <input
                  className="signup-input-field"
                  placeholder="Email"
                  onChange={handleEmailId}
                  value={emailId}
                  autoFocus
                />
              </div>
            </div>
            <div></div>
          </div>
        
        <div
          style={{
            width: 270,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="H4">
            Choose a <span>username</span>
          </div>

          <div>
            <div className="chat-box-footer-input signup-input">
              <input
                className="signup-input-field"
                placeholder="@username"
                onChange={handleUsername}
                value={username}
                autoFocus={!createWallet}
              />
            </div>
          </div>
          <div>
            <div className="connectButton centered">
              <div
                style={{
                  width: 200,
                  height: 40,
                  background:
                    "linear-gradient(270deg, #ACD7DB 5.21%, #4FB3BF 49.48%, #00838F 100%)",
                }}
                className="btn-grad "
                onClick={createWallet ? handleCreateWallet : handleSignup}
              >
                next
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contentSignup__button" onClick={handleSignOut} >
                    Sign out
                </div> */}

      <div className="footer centered">
        <div className="build-on"></div>
      </div>

      {/* Model For Connecting */}
      <Modal
        className="model"
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <img height={80} width={80} src={exclamatoryLogo} />
        <div className="H6">Invalid Username</div>
        <div className="button" onClick={() => setModalIsOpen(false)}>
          Close
        </div>
      </Modal>

      {/* MainPage ends */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity={snackbarSeverity}
          sx={{ width: "100%", right: 0 }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SignupPage;
