export default class ResponseModel {

    //Init variables
    msg; 
    data;
    status; 

    constructor(data, status=true, msg='Something went wrong :/') {
        this.msg = msg;
        this.data = data; 
        this.status = status; 

        if(!status){
            // LOGGER.log(msg);
        }
    }

}