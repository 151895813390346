import axios from 'axios';
import { BACKEND_END_BASE_URL ,SEND_EMAIL_API_KEY, TOKEN_PRICE_API} from '../constants/serverConfig';
// const BACKEND_END_BASE_URL = "http://167.99.92.241:4000/";
import ResponseModel from './model/response';

export const createUser = async ({ userName, nearAccount,emailId }) => {

    const req = {
        user_name: userName,
        near_id: nearAccount,
        email_id:emailId,
    };

    return await axios.post(BACKEND_END_BASE_URL + 'user' + '/create-user', req)
        .then(res => {
         
            return new ResponseModel(res.data);
        })
        .catch(e => {
            console.log(e);
            let res = e.response?.data + '';
            console.log(res);
            if (res.includes("duplicate")) {
              
                return new ResponseModel(null, false, "Username or Email Id already exists");
            } else {
                return new ResponseModel(null, false);
            }
        });
}


export const isUserName = async ({ userName }) => {

    const req = {
        user_name: userName,
    };

    await axios.post(BACKEND_END_BASE_URL + 'user' + '/get-user-by-name', req)
        .then(res => console.log(res.data));
}

export const isNearWallet = async ({ nearAccount }) => {
    console.log(BACKEND_END_BASE_URL);
    const req = {
        near_id: nearAccount,
    };

    return await axios.post(BACKEND_END_BASE_URL + 'user' + '/get-user-by-near-wallet', req)
        .then(res => res.data);
    // .catch(e=>{
    //     return e;
    // });
}


export const createConversation = async (req) => {
    return await axios.post(BACKEND_END_BASE_URL + 'conversation' + '/create-conversation', req)
        .then(res => {
            return new ResponseModel(res.data);
        })
        .catch(e => {
            let res = e.response.data + '';
            return new ResponseModel(null, false);
        });
}


export const getConversationByAccountId = async (req) => {

    const res = await axios.post(BACKEND_END_BASE_URL + 'conversation' + '/get-conversation-by-account', req);
    
    return res;
}

export const sendMessage = async (req) => {
   
    // const myApi = axios.create({
    //     baseURL: 'http://someUrl/someEndpoint',
    //     timeout: 10000,
    //     withCredentials: true,
    //     transformRequest: [(data) => JSON.stringify(data.data)],
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //     }
    //   });'
    req["unixTime"] = 2324434;
    return await axios.post(BACKEND_END_BASE_URL + 'message' + '/create-message'
    , req
    )
    .then(res => {
        console.log('sendMessage ')
        console.log(res)
        return new ResponseModel(res.data);
    })
    .catch(e => {
        console.log('Update sendMessage Error');
        console.log(e);
        return new ResponseModel(null, false);
    });;
    console.log("Send Message .....")
}


export const getMessageByConversationId = async (req) => {

    const res = await axios.post(BACKEND_END_BASE_URL + 'message' + '/get-message-by-conversation-id', req);

    return res;
}


export const getConversationById = async (req) => {

    const res = await axios.post(BACKEND_END_BASE_URL + 'conversation' + '/get-conversation-id', req);

    return res;
}



export const updateConversation = async (req) => {

    return await axios.post(BACKEND_END_BASE_URL + 'conversation' + '/update-conversation', req)
    .then(res => {
        return new ResponseModel(res);
    })
    .catch(e => {
        console.log('Update Conversation Error');
        console.log(e);
        return new ResponseModel(null, false);
    });;
}


export const deleteConversation = async (id) => {

    const res = await axios.get(BACKEND_END_BASE_URL + 'conversation' + '/delete-conversation/' + id);

    return res;
}


export const getCurrencyRate = async (tokenName) => {

    //  const res = await axios.get(TOKEN_PRICE_API + tokenName);
    // const {tickers } = res.data ?? [];
    // let price = 0.0;
    // if(tickers != undefined)
    // tickers.forEach(element => {
    //    const  { market , converted_last } = element;
    //    if(market.name == "Binance" || market.identifier == "binance"){
    //         price = converted_last.usd;
    //    }
    // });
    return 0;
    // return price;
}


export const sendEmail = async(emailId,userName,htmlContent) =>{
    // console.log("email" ,SEND_EMAIL_API_KEY)
    //    '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',
        // let url = seed

        //    htmlContent ="<html><head></head><body><p>Hello,</p> "+'url'+" </p></body></html>";
        let data ={
            "sender":{  
                "name":"Icebreak",
                "email":"no-reply@icebreak.com"
             },
             "to":[  
                {  
                   "email":emailId,
                   "name":userName
                }
             ],
             "subject":"Important Information - Saving Your Seed Phrase",
             "htmlContent":htmlContent
          }
        


        const headers = {
            'accept': 'application/json',
            'api-key':SEND_EMAIL_API_KEY,
            'content-type': 'application/json'
          }
          
          const res =  axios.post("https://api.brevo.com/v3/smtp/email", data, {
              headers: headers
            }) .then(res => {
           
                return new ResponseModel("success");
            })
            .catch(e => {
                console.log(e);
                return new ResponseModel(null, false);
            });;


        return res;
}





